import Link from 'next/link';
import { Children, ReactNode } from 'react';
import { UrlObject } from 'url';
import { StyledButton, StyledLink } from './HeaderButton.styles';

export type SupportedRadius = 16 | 32;
export type ButtonVariant = 'primary' | 'secondary' | 'transparent';
export type ButtonSize = 'sm' | 'md';
export type ButtonType = 'submit' | 'reset' | 'button';

export interface Props {
  children: ReactNode;
  variant?: ButtonVariant;
  borderRadius?: SupportedRadius;
  size?: ButtonSize;
  className?: string;
  'aria-label'?: string;
  'aria-expanded'?: boolean;
  'aria-haspopup'?: 'dialog' | boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  href?: string | UrlObject;
  type?: ButtonType;
  id?: string;
}

const HeaderButton = ({
  children,
  variant = 'primary',
  borderRadius = 32,
  'aria-label': ariaLabel,
  'aria-expanded': ariaExpanded,
  'aria-haspopup': ariaHasPopup,
  onClick,
  size = 'md',
  href,
  type,
  className,
  id,
}: Props) => {
  const hasMultipleChildren = Children.toArray(children).filter((c) => c).length > 1;
  if (href) {
    return (
      <Link legacyBehavior href={href} passHref>
        <StyledLink
          aria-label={ariaLabel}
          aria-expanded={ariaExpanded}
          aria-haspopup={ariaHasPopup}
          $variant={variant}
          $borderRadius={borderRadius}
          onClick={onClick}
          $buttonSize={size}
          $hasMultipleChildren={hasMultipleChildren}
          className={className}
        >
          {children}
        </StyledLink>
      </Link>
    );
  }
  return (
    <StyledButton
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      $variant={variant}
      $borderRadius={borderRadius}
      onClick={onClick}
      $buttonSize={size}
      $hasMultipleChildren={hasMultipleChildren}
      type={type}
      className={className}
      id={id}
    >
      {children}
    </StyledButton>
  );
};

export default HeaderButton;
