/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodBasicWishListViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartEntryStatusViewModel } from '../dto';
// @ts-ignore
import { AxfoodCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodWishListViewModel } from '../dto';
// @ts-ignore
import { WishlistForm } from '../dto';
/**
 * WishListApi - axios parameter creator
 * @export
 */
export const WishListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add product to cart from wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToCartUsingPOST: async (id: string, wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProductsToCartUsingPOST', 'id', id)
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('addProductsToCartUsingPOST', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/wishlist/{id}/cart`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add products to user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToUserWishlistUsingPOST: async (id: string, wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addProductsToUserWishlistUsingPOST', 'id', id)
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('addProductsToUserWishlistUsingPOST', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/user-wishlist/{id}/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add site wish list to user wish list
         * @param {string} siteWishlistId siteWishlistId
         * @param {string} userWishlistId userWishlistId
         * @param {boolean} [createNew] createNew
         * @param {string} [name] name
         * @param {string} [description] description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSiteWishlistToUserWishlistUsingPOST: async (siteWishlistId: string, userWishlistId: string, createNew?: boolean, name?: string, description?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'siteWishlistId' is not null or undefined
            assertParamExists('addSiteWishlistToUserWishlistUsingPOST', 'siteWishlistId', siteWishlistId)
            // verify required parameter 'userWishlistId' is not null or undefined
            assertParamExists('addSiteWishlistToUserWishlistUsingPOST', 'userWishlistId', userWishlistId)
            const localVarPath = `/axfood/rest/wishlist-to-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (siteWishlistId !== undefined) {
                localVarQueryParameter['siteWishlistId'] = siteWishlistId;
            }

            if (userWishlistId !== undefined) {
                localVarQueryParameter['userWishlistId'] = userWishlistId;
            }

            if (createNew !== undefined) {
                localVarQueryParameter['createNew'] = createNew;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEntriesAvailabilityUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkEntriesAvailabilityUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/wishlist/changes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check entries in user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEntriesInUserWishlistUsingPUT: async (id: string, wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkEntriesInUserWishlistUsingPUT', 'id', id)
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('checkEntriesInUserWishlistUsingPUT', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/user-wishlist/{id}/check`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create user wish list
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWishlistUsingPOST: async (wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('createWishlistUsingPOST', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/user-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWishListUsingDELETE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserWishListUsingDELETE', 'id', id)
            const localVarPath = `/axfood/rest/user-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete multiple user wishlists
         * @param {Array<string>} wishlistIds wishlistIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWishListsUsingDELETE: async (wishlistIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wishlistIds' is not null or undefined
            assertParamExists('deleteUserWishListsUsingDELETE', 'wishlistIds', wishlistIds)
            const localVarPath = `/axfood/rest/user-wishlist/remove-many`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get full user wish lists
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullUserWishListsUsingGET: async (sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/full-user-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get recipe wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRecipeWishlistUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/recipe-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get site wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteWishlistUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSiteWishlistUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/site-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user wish lists
         * @param {boolean} [basic] basic
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishListsUsingGET: async (basic?: boolean, sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/user-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (basic !== undefined) {
                localVarQueryParameter['basic'] = basic;
            }

            if (sorting !== undefined) {
                localVarQueryParameter['sorting'] = sorting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user wish list filter non salable
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishlistFilterNonSalableUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserWishlistFilterNonSalableUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/user-wishlist-filter-non-salable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishlistUsingGET: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserWishlistUsingGET', 'id', id)
            const localVarPath = `/axfood/rest/user-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get site wish lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWishListsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/site-wishlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove entries from user wish list
         * @param {string} listId listId
         * @param {Array<string>} identifiers identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntriesFromUserWishlistUsingDELETE: async (listId: string, identifiers: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('removeEntriesFromUserWishlistUsingDELETE', 'listId', listId)
            // verify required parameter 'identifiers' is not null or undefined
            assertParamExists('removeEntriesFromUserWishlistUsingDELETE', 'identifiers', identifiers)
            const localVarPath = `/axfood/rest/user-wishlist/{listId}/products`
                .replace(`{${"listId"}}`, encodeURIComponent(String(listId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identifiers, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove entry from user wish list
         * @param {string} listId listId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromUserWishlistUsingDELETE: async (listId: string, productCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('removeEntryFromUserWishlistUsingDELETE', 'listId', listId)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('removeEntryFromUserWishlistUsingDELETE', 'productCode', productCode)
            const localVarPath = `/axfood/rest/user-wishlist/{listId}/product/{productCode}`
                .replace(`{${"listId"}}`, encodeURIComponent(String(listId)))
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update wish list sorting
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWishlistSortingUsingPUT: async (id: string, wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWishlistSortingUsingPUT', 'id', id)
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('updateWishlistSortingUsingPUT', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/user-wishlist/{id}/sorting`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWishlistUsingPOST: async (id: string, wishlistForm: WishlistForm, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateWishlistUsingPOST', 'id', id)
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('updateWishlistUsingPOST', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/user-wishlist/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {string} listId listId
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderEntriesUsingGET1: async (listId: string, checkStock?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listId' is not null or undefined
            assertParamExists('validateOrderEntriesUsingGET1', 'listId', listId)
            const localVarPath = `/axfood/rest/wishlist/validate/{listId}`
                .replace(`{${"listId"}}`, encodeURIComponent(String(listId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (checkStock !== undefined) {
                localVarQueryParameter['checkStock'] = checkStock;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWishlistEntriesUsingPOST: async (wishlistForm: WishlistForm, checkStock?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'wishlistForm' is not null or undefined
            assertParamExists('validateWishlistEntriesUsingPOST', 'wishlistForm', wishlistForm)
            const localVarPath = `/axfood/rest/wishlist/validate-entries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (checkStock !== undefined) {
                localVarQueryParameter['checkStock'] = checkStock;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(wishlistForm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WishListApi - functional programming interface
 * @export
 */
export const WishListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WishListApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add product to cart from wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductsToCartUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProductsToCartUsingPOST(id, wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add products to user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProductsToUserWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProductsToUserWishlistUsingPOST(id, wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add site wish list to user wish list
         * @param {string} siteWishlistId siteWishlistId
         * @param {string} userWishlistId userWishlistId
         * @param {boolean} [createNew] createNew
         * @param {string} [name] name
         * @param {string} [description] description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSiteWishlistToUserWishlistUsingPOST(siteWishlistId: string, userWishlistId: string, createNew?: boolean, name?: string, description?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSiteWishlistToUserWishlistUsingPOST(siteWishlistId, userWishlistId, createNew, name, description, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEntriesAvailabilityUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodCartEntryStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEntriesAvailabilityUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check entries in user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkEntriesInUserWishlistUsingPUT(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkEntriesInUserWishlistUsingPUT(id, wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create user wish list
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWishlistUsingPOST(wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWishlistUsingPOST(wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserWishListUsingDELETE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodWishListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserWishListUsingDELETE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete multiple user wishlists
         * @param {Array<string>} wishlistIds wishlistIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserWishListsUsingDELETE(wishlistIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodWishListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserWishListsUsingDELETE(wishlistIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get full user wish lists
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFullUserWishListsUsingGET(sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodWishListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFullUserWishListsUsingGET(sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get recipe wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecipeWishlistUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecipeWishlistUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get site wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSiteWishlistUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSiteWishlistUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user wish lists
         * @param {boolean} [basic] basic
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserWishListsUsingGET(basic?: boolean, sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodBasicWishListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserWishListsUsingGET(basic, sorting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user wish list filter non salable
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserWishlistFilterNonSalableUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserWishlistFilterNonSalableUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserWishlistUsingGET(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserWishlistUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get site wish lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWishListsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodWishListViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWishListsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove entries from user wish list
         * @param {string} listId listId
         * @param {Array<string>} identifiers identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEntriesFromUserWishlistUsingDELETE(listId: string, identifiers: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntriesFromUserWishlistUsingDELETE(listId, identifiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove entry from user wish list
         * @param {string} listId listId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeEntryFromUserWishlistUsingDELETE(listId: string, productCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntryFromUserWishlistUsingDELETE(listId, productCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update wish list sorting
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWishlistSortingUsingPUT(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWishlistSortingUsingPUT(id, wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodWishListViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWishlistUsingPOST(id, wishlistForm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {string} listId listId
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderEntriesUsingGET1(listId: string, checkStock?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodCartEntryStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateOrderEntriesUsingGET1(listId, checkStock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateWishlistEntriesUsingPOST(wishlistForm: WishlistForm, checkStock?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodCartEntryStatusViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateWishlistEntriesUsingPOST(wishlistForm, checkStock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WishListApi - factory interface
 * @export
 */
export const WishListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WishListApiFp(configuration)
    return {
        /**
         * 
         * @summary Add product to cart from wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToCartUsingPOST(id: string, wishlistForm: WishlistForm, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.addProductsToCartUsingPOST(id, wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add products to user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToUserWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: any): AxiosPromise<void> {
            return localVarFp.addProductsToUserWishlistUsingPOST(id, wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add site wish list to user wish list
         * @param {string} siteWishlistId siteWishlistId
         * @param {string} userWishlistId userWishlistId
         * @param {boolean} [createNew] createNew
         * @param {string} [name] name
         * @param {string} [description] description
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSiteWishlistToUserWishlistUsingPOST(siteWishlistId: string, userWishlistId: string, createNew?: boolean, name?: string, description?: string, options?: any): AxiosPromise<void> {
            return localVarFp.addSiteWishlistToUserWishlistUsingPOST(siteWishlistId, userWishlistId, createNew, name, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEntriesAvailabilityUsingGET(id: string, options?: any): AxiosPromise<Array<AxfoodCartEntryStatusViewModel>> {
            return localVarFp.checkEntriesAvailabilityUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check entries in user wish list
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkEntriesInUserWishlistUsingPUT(id: string, wishlistForm: WishlistForm, options?: any): AxiosPromise<void> {
            return localVarFp.checkEntriesInUserWishlistUsingPUT(id, wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create user wish list
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWishlistUsingPOST(wishlistForm: WishlistForm, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.createWishlistUsingPOST(wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWishListUsingDELETE(id: string, options?: any): AxiosPromise<Array<AxfoodWishListViewModel>> {
            return localVarFp.deleteUserWishListUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete multiple user wishlists
         * @param {Array<string>} wishlistIds wishlistIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserWishListsUsingDELETE(wishlistIds: Array<string>, options?: any): AxiosPromise<Array<AxfoodWishListViewModel>> {
            return localVarFp.deleteUserWishListsUsingDELETE(wishlistIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get full user wish lists
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFullUserWishListsUsingGET(sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: any): AxiosPromise<Array<AxfoodWishListViewModel>> {
            return localVarFp.getFullUserWishListsUsingGET(sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get recipe wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeWishlistUsingGET(id: string, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.getRecipeWishlistUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get site wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSiteWishlistUsingGET(id: string, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.getSiteWishlistUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user wish lists
         * @param {boolean} [basic] basic
         * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishListsUsingGET(basic?: boolean, sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: any): AxiosPromise<Array<AxfoodBasicWishListViewModel>> {
            return localVarFp.getUserWishListsUsingGET(basic, sorting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user wish list filter non salable
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishlistFilterNonSalableUsingGET(id: string, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.getUserWishlistFilterNonSalableUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user wish list
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserWishlistUsingGET(id: string, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.getUserWishlistUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get site wish lists
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWishListsUsingGET(options?: any): AxiosPromise<Array<AxfoodWishListViewModel>> {
            return localVarFp.getWishListsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove entries from user wish list
         * @param {string} listId listId
         * @param {Array<string>} identifiers identifiers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntriesFromUserWishlistUsingDELETE(listId: string, identifiers: Array<string>, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.removeEntriesFromUserWishlistUsingDELETE(listId, identifiers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove entry from user wish list
         * @param {string} listId listId
         * @param {string} productCode productCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeEntryFromUserWishlistUsingDELETE(listId: string, productCode: string, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.removeEntryFromUserWishlistUsingDELETE(listId, productCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update wish list sorting
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWishlistSortingUsingPUT(id: string, wishlistForm: WishlistForm, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.updateWishlistSortingUsingPUT(id, wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check entries availability
         * @param {string} id id
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: any): AxiosPromise<AxfoodWishListViewModel> {
            return localVarFp.updateWishlistUsingPOST(id, wishlistForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {string} listId listId
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderEntriesUsingGET1(listId: string, checkStock?: boolean, options?: any): AxiosPromise<Array<AxfoodCartEntryStatusViewModel>> {
            return localVarFp.validateOrderEntriesUsingGET1(listId, checkStock, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate list entries, with replacement for conflicts
         * @param {WishlistForm} wishlistForm wishlistForm
         * @param {boolean} [checkStock] checkStock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateWishlistEntriesUsingPOST(wishlistForm: WishlistForm, checkStock?: boolean, options?: any): AxiosPromise<Array<AxfoodCartEntryStatusViewModel>> {
            return localVarFp.validateWishlistEntriesUsingPOST(wishlistForm, checkStock, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WishListApi - object-oriented interface
 * @export
 * @class WishListApi
 * @extends {BaseAPI}
 */
export class WishListApi extends BaseAPI {
    /**
     * 
     * @summary Add product to cart from wish list
     * @param {string} id id
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public addProductsToCartUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).addProductsToCartUsingPOST(id, wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add products to user wish list
     * @param {string} id id
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public addProductsToUserWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).addProductsToUserWishlistUsingPOST(id, wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add site wish list to user wish list
     * @param {string} siteWishlistId siteWishlistId
     * @param {string} userWishlistId userWishlistId
     * @param {boolean} [createNew] createNew
     * @param {string} [name] name
     * @param {string} [description] description
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public addSiteWishlistToUserWishlistUsingPOST(siteWishlistId: string, userWishlistId: string, createNew?: boolean, name?: string, description?: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).addSiteWishlistToUserWishlistUsingPOST(siteWishlistId, userWishlistId, createNew, name, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check entries availability
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public checkEntriesAvailabilityUsingGET(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).checkEntriesAvailabilityUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check entries in user wish list
     * @param {string} id id
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public checkEntriesInUserWishlistUsingPUT(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).checkEntriesInUserWishlistUsingPUT(id, wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create user wish list
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public createWishlistUsingPOST(wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).createWishlistUsingPOST(wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public deleteUserWishListUsingDELETE(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).deleteUserWishListUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete multiple user wishlists
     * @param {Array<string>} wishlistIds wishlistIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public deleteUserWishListsUsingDELETE(wishlistIds: Array<string>, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).deleteUserWishListsUsingDELETE(wishlistIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get full user wish lists
     * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getFullUserWishListsUsingGET(sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getFullUserWishListsUsingGET(sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get recipe wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getRecipeWishlistUsingGET(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getRecipeWishlistUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get site wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getSiteWishlistUsingGET(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getSiteWishlistUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user wish lists
     * @param {boolean} [basic] basic
     * @param {'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC'} [sorting] sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getUserWishListsUsingGET(basic?: boolean, sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC', options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getUserWishListsUsingGET(basic, sorting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user wish list filter non salable
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getUserWishlistFilterNonSalableUsingGET(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getUserWishlistFilterNonSalableUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user wish list
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getUserWishlistUsingGET(id: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getUserWishlistUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get site wish lists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public getWishListsUsingGET(options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).getWishListsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove entries from user wish list
     * @param {string} listId listId
     * @param {Array<string>} identifiers identifiers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public removeEntriesFromUserWishlistUsingDELETE(listId: string, identifiers: Array<string>, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).removeEntriesFromUserWishlistUsingDELETE(listId, identifiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove entry from user wish list
     * @param {string} listId listId
     * @param {string} productCode productCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public removeEntryFromUserWishlistUsingDELETE(listId: string, productCode: string, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).removeEntryFromUserWishlistUsingDELETE(listId, productCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update wish list sorting
     * @param {string} id id
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public updateWishlistSortingUsingPUT(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).updateWishlistSortingUsingPUT(id, wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check entries availability
     * @param {string} id id
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public updateWishlistUsingPOST(id: string, wishlistForm: WishlistForm, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).updateWishlistUsingPOST(id, wishlistForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate list entries, with replacement for conflicts
     * @param {string} listId listId
     * @param {boolean} [checkStock] checkStock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public validateOrderEntriesUsingGET1(listId: string, checkStock?: boolean, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).validateOrderEntriesUsingGET1(listId, checkStock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate list entries, with replacement for conflicts
     * @param {WishlistForm} wishlistForm wishlistForm
     * @param {boolean} [checkStock] checkStock
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WishListApi
     */
    public validateWishlistEntriesUsingPOST(wishlistForm: WishlistForm, checkStock?: boolean, options?: AxiosRequestConfig) {
        return WishListApiFp(this.configuration).validateWishlistEntriesUsingPOST(wishlistForm, checkStock, options).then((request) => request(this.axios, this.basePath));
    }
}
