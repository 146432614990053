import { getPage, PageType } from '@api';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import { useAppSelector } from '@hooks/redux';
import { useSearchParams } from 'next/navigation';
import useCustomer from '@hooks/useCustomer';
import { selectCartDeliveryModeCode } from '@selectors/cart';
import { selectActiveStoreId } from '@selectors/delivery';
import useSWR from 'swr';

/**
 * @summary hook for storing the data from a cms page in SWR
 */
const useCmsPage = (cmsPageId?: string, code?: string, pageType: PageType = 'ContentPage') => {
  const { customerId } = useCustomer();
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const deliveryModeCode = useAppSelector(selectCartDeliveryModeCode);

  const searchParams = useSearchParams();

  const cmsTicketId = searchParams?.get('cmsTicketId') || undefined;
  const shouldFetch = !!cmsPageId || !!code;

  const key = shouldFetch
    ? {
        endpoint: 'cms/page',
        label: cmsPageId,
        code,
        pageType,
        cmsTicketId, // only used in cmscockpit preview mode
        customerId,
        activeStoreId,
        deliveryModeCode,
      }
    : null;

  const { data } = useSWR(
    key,
    ({ label, code, cmsTicketId }) => {
      return getPage({ label, code, cmsTicketId, pageType });
    },
    {
      refreshInterval: FORTY_MINUTES_IN_MS,
      keepPreviousData: true,
    },
  );

  return {
    cmsPage: shouldFetch ? data : undefined,
  };
};

export default useCmsPage;
