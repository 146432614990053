import Icon from '@components/atoms/Icon';
import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import LINKS from '@constants/links';
import useConfig from '@hooks/useConfig';
import useTranslation from 'next-translate/useTranslation';

import CartNicotineNotification from '@components/molecules/CartNicotineNotification';
import IconArrowRightCircle from '@public/icons/regularIcons/icon-arrow-right-circle.svg';
import { CartNicotineNotificationWrapper, Container, RectangleButton } from './MiniCartPaneFooter.styles';

interface Props {
  cartHasProducts: boolean;
  cartHasNicotineProduct?: boolean;
  onClick: (direction: string) => void;
}

const MiniCartPaneFooter = ({ cartHasProducts, cartHasNicotineProduct, onClick }: Props) => {
  const { t } = useTranslation('minicart');
  const { config } = useConfig();
  const CART_URL = `${config?.PUBLIC_HOST}${LINKS.CART}`;

  const handleClick = () => {
    cartHasProducts && onClick('bottom');
  };

  return (
    <Container>
      {cartHasNicotineProduct && (
        <CartNicotineNotificationWrapper>
          <CartNicotineNotification />
        </CartNicotineNotificationWrapper>
      )}
      <LinkArea href={CART_URL} disabled={!cartHasProducts} onClick={handleClick}>
        <RectangleButton type="button" data-testid="to-cart-button-2" disabled={!cartHasProducts}>
          <Paragraph size="xl">{t('minicart->buttons->toCartBtnText')}</Paragraph>
          <Icon margin="left" icon={IconArrowRightCircle} color="white" data-testid="icon-arrow-right-circle" />
        </RectangleButton>
      </LinkArea>
    </Container>
  );
};

export default MiniCartPaneFooter;
