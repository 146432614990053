import HeaderButton from '@components/atoms/HeaderButton';
import Icon from '@components/atoms/Icon';
import DeliveryInformationModal from '@components/molecules/DeliveryInformationModal/DeliveryInformationModal';
import CartButton from '@components/organisms/Header/Cart/CartButton';
import DeliveryButton from '@components/organisms/Header/DeliveryButton';
import useOpenDeliveryModal from '@components/organisms/Header/hooks/useOpenDeliveryModal/useOpenDeliveryModal';
import useHeaderResponsive from '@components/organisms/Header/useHeaderResponsive/useHeaderResponsive';
import LINKS from '@constants/links';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useOnClickOutside from '@hooks/useOnClickOutside';
import IconHeadset from '@public/icons/regularIcons/icon-headset.svg';
import MenuIcon from '@public/icons/regularIcons/icon-menu.svg';
import { selectCartDeliveryModeCode, selectCartSlot } from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import { memo, useRef, useState } from 'react';
import { selectSideNavIsOpen, sideNavToggled } from 'redux/slices/sideNav';
import AccountButton from './AccountButton';
import {
  Hamburger,
  HeaderContentWrapper,
  MobileButtonBar,
  MobileButtonContainer,
  SearchDeliveryWrapper,
  SearchSideContainer,
  SearchWrapper,
  TopLeftContainer,
  TopRightContainer,
  VerticalBar,
} from './Header.styles';
import Logo from './Logo';
import MenuLinks from './MenuLinks';
import MobileAssortmentButton from './MobileAssortmentButton';
import Search from './Search';
import { MenuLinkData } from './hooks/useTopBarData';

export interface Props {
  toggleTakeoverMenu: () => void;
  showMenuLinks: boolean;
  logotype: SimpleMedia;
  menuLinks: MenuLinkData[];
  isScrollingDown: boolean;
  searchEnabled: boolean;
}

const DefaultHeader = ({
  toggleTakeoverMenu,
  showMenuLinks: ariaExpanded,
  logotype,
  menuLinks,
  isScrollingDown,
  searchEnabled,
}: Props) => {
  const { t } = useTranslation('common');
  const {
    showHamburgerButton,
    showCustomerServiceLink,
    showAccountButtonText,
    showCartButtonAmount,
    showMenuLinks,
    showDeliveryButton,
    showMobileButtonBar,
    hideRightColumnOnFocus,
  } = useHeaderResponsive();
  const { openDeliveryModal } = useOpenDeliveryModal();
  const dispatch = useAppDispatch();
  const sideNavIsOpen = useAppSelector(selectSideNavIsOpen);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const confirmedSlot = useAppSelector(selectCartSlot);
  const ref = useRef(null);
  const deliveryMode = useAppSelector(selectCartDeliveryModeCode);

  const [searchIsFocused, setSearchIsFocused] = useState(false);

  const openDeliveryInformationModal = () => {
    if (confirmedSlot || deliveryMode) {
      setModalIsOpen(!modalIsOpen);
    } else {
      setModalIsOpen(false);
      openDeliveryModal();
    }
  };

  useOnClickOutside(ref, () => {
    setModalIsOpen(false);
  });
  const assortmentButtonClick = () => {
    dispatch(sideNavToggled(!sideNavIsOpen));
  };

  const searchSize = isScrollingDown ? 'sm' : 'md';

  const shouldRenderHamburger = (showHamburgerButton && !searchIsFocused) || (showHamburgerButton && !isScrollingDown);
  const shouldRenderTopRightContainer = !searchIsFocused || !hideRightColumnOnFocus || !isScrollingDown;

  return (
    <>
      <HeaderContentWrapper>
        <TopLeftContainer>
          {shouldRenderHamburger && (
            <Hamburger
              aria-label={t('ariaLabels->menu')}
              aria-expanded={ariaExpanded}
              aria-haspopup="dialog"
              variant="secondary"
              onClick={toggleTakeoverMenu}
              borderRadius={16}
            >
              <Icon color="black" icon={MenuIcon} />
            </Hamburger>
          )}
          {(!isScrollingDown || !showHamburgerButton) && <Logo imageData={logotype} />}
          {showMenuLinks && !isScrollingDown && <MenuLinks menuLinks={menuLinks} />}
        </TopLeftContainer>
        {shouldRenderTopRightContainer && (
          <TopRightContainer>
            {showCustomerServiceLink && !isScrollingDown && (
              <HeaderButton
                href={LINKS.FAQ}
                aria-label={t('ariaLabels->customerService')}
                variant="secondary"
                borderRadius={16}
              >
                <Icon color="black" icon={IconHeadset} mouseoverText={t('ariaLabels->customerService')} />
              </HeaderButton>
            )}
            {(!isScrollingDown || showAccountButtonText) && (
              <AccountButton showAccountButtonText={showAccountButtonText} />
            )}
            <CartButton showTotalProductAmount={showCartButtonAmount} />
          </TopRightContainer>
        )}
        {searchEnabled && (
          <SearchDeliveryWrapper $isScrollingDown={isScrollingDown}>
            {showDeliveryButton && !isScrollingDown && (
              <SearchSideContainer ref={ref}>
                <DeliveryButton modalIsOpen={modalIsOpen} onClick={openDeliveryInformationModal} />
                {modalIsOpen && (
                  <DeliveryInformationModal popperReference={ref.current} onClick={openDeliveryInformationModal} />
                )}
              </SearchSideContainer>
            )}
            <SearchWrapper>
              <Search size={searchSize} setSearchIsFocused={setSearchIsFocused} />
            </SearchWrapper>
            {showDeliveryButton && !isScrollingDown && <SearchSideContainer />}
          </SearchDeliveryWrapper>
        )}
      </HeaderContentWrapper>
      {showMobileButtonBar && (
        <MobileButtonBar>
          <MobileButtonContainer>
            <MobileAssortmentButton onClick={assortmentButtonClick} />
          </MobileButtonContainer>
          <VerticalBar />

          <MobileButtonContainer ref={ref}>
            <DeliveryButton modalIsOpen={modalIsOpen} mobile onClick={openDeliveryInformationModal} />
            {modalIsOpen && (
              <DeliveryInformationModal popperReference={ref.current} mobile onClick={openDeliveryInformationModal} />
            )}
          </MobileButtonContainer>
        </MobileButtonBar>
      )}
    </>
  );
};

export default memo(DefaultHeader);
