import Heading from 'components/atoms/__DEPRECATED__/Heading';
import Text from '@components/atoms/Text';
import Button from '@components/atoms/__DEPRECATED__/Button';
import Modal from '@components/molecules/__DEPRECATED__/Modal/index';
import isFrontend from '@helpers/isFrontend';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { ButtonWrapper, Container, IconWrapper, TextWrapper } from './MessageModal.styles';

interface MessageModalProps {
  closeHandler?: () => void;
  text: string;
  onConfirm?: () => void;
  title?: string;
  icon?: ReactNode;
  extraInfo?: ReactNode;
  withCloseButton?: boolean;
  iconSize?: number;
}

/**
 * This Modal should be considered our general popup to be used when we need to alert users.
 * The design involved in such cases should be done with the styling of this component in mind
 */

const MessageModal = ({
  closeHandler,
  text,
  onConfirm,
  title,
  icon,
  withCloseButton = false,
  extraInfo = null,
  iconSize = 34,
}: MessageModalProps) => {
  const modalContainer = isFrontend() ? document.getElementById('modal-container') : null;

  if (!isFrontend()) {
    return null;
  }
  return ReactDOM.createPortal(
    <Modal
      size="small"
      isOpen
      zIndex={202}
      withPadding={false}
      closeHandler={closeHandler}
      withCloseButton={withCloseButton}
      data-testid="message-modal"
    >
      <Container>
        {icon && <IconWrapper size={iconSize}>{icon}</IconWrapper>}
        {title && (
          <Heading type="h2" align="center">
            {title}
          </Heading>
        )}
        <TextWrapper>
          <Text type="p" size="content-text">
            {text}
          </Text>
        </TextWrapper>
        {!!onConfirm && (
          <ButtonWrapper>
            <Button onClick={() => onConfirm()}>OK</Button>
          </ButtonWrapper>
        )}

        {!!extraInfo && <TextWrapper>{extraInfo}</TextWrapper>}
      </Container>
    </Modal>,
    modalContainer as HTMLElement
  );
};

export default MessageModal;
