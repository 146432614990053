import {
  AxfoodOrderProductViewModel,
  AxfoodReplacedOrderProductViewModel,
  AxfoodReplacementProductViewModel,
} from '@api/generated/storefront';
import { mapHybrisProduct, Promotion } from '@components/molecules/Product/ProductDataMapper';
import { stringValueOrEmpty, stringValueOrUndefined } from '@helpers/mapping';
import { validPriceOrFallback } from '@helpers/money';

type HybrisOrderProduct = AxfoodOrderProductViewModel | AxfoodReplacedOrderProductViewModel;

export enum Change {
  QUANTITY = 'quantity',
  REPLACED = 'replaced',
  REPLACEMENT = 'replacement',
}

export interface OrderRow {
  change?: Change;
  key: string;
  name: string;
  image?: string;
  imageAltText: string;
  description: string;
  price: string;
  deposit?: string;
  quantity: string;
  totalPrice: string;
  orderedQuantity?: string;
  promotion?: string;
  discount?: string;
  quantityInRecipe?: string;
}

const addOrderRowBase = (orderProduct: HybrisOrderProduct, orderRow: Partial<OrderRow>) => {
  // productLine2 delivers different kind of information depending on the product
  // E.g. fruits and vegetables shows country of origin in this property
  const description = stringValueOrEmpty(orderProduct.productLine2);
  return {
    key: orderProduct.code,
    image: stringValueOrUndefined(orderProduct.thumbnail?.url),
    imageAltText: orderProduct.thumbnail?.altText || `${orderProduct.name}-bild`,
    name: orderProduct.name,
    description,
    price: stringValueOrEmpty(orderProduct.price),
    deposit: stringValueOrUndefined(orderProduct.depositPrice),
    quantity: stringValueOrEmpty(orderProduct.displayQuantity),
    totalPrice: stringValueOrEmpty(orderProduct.totalPrice),
    discount: validPriceOrFallback(orderProduct.totalDiscount),
    quantityInRecipe: stringValueOrUndefined(orderProduct.quantityIncludedInRecipe),
    ...orderRow,
  };
};

// anonymous orders are not qualified for loyalty promotions
const isQualifiedForPromotion = (isAnonymousOrder: boolean, promotion?: Promotion) =>
  !(isAnonymousOrder && promotion?.isLoyalty);

export const mapOrderRowFromCategoryProduct = (
  product: AxfoodOrderProductViewModel,
  isAnonymousOrder: boolean,
  paymentCaptured: boolean
): OrderRow => {
  const row = addOrderRowBase(product, {});
  const mappedProduct = mapHybrisProduct(product);

  const originalQuantity = product.displayOriginalQuantity || '';

  return {
    ...row,
    quantity: paymentCaptured ? row.quantity : originalQuantity,
    promotion:
      mappedProduct.hasPromotion && isQualifiedForPromotion(isAnonymousOrder, mappedProduct.promotion)
        ? [mappedProduct.promotion?.conditionLabel, mappedProduct.promotion?.rewardLabel].join(' ')
        : undefined,
  };
};

export const mapOrderRowFromNotDeliveredProduct = (product: AxfoodOrderProductViewModel): OrderRow => {
  return addOrderRowBase(product, {
    change: Change.QUANTITY,
    orderedQuantity: product.displayOriginalQuantity,
  });
};

export const mapOrderRowFromPartlyDeliveredProduct = (product: AxfoodOrderProductViewModel): OrderRow => {
  return addOrderRowBase(product, {
    change: Change.QUANTITY,
    orderedQuantity: product.displayOriginalQuantity,
  });
};

export const mapOrderRowFromReplacedProduct = (product: AxfoodReplacedOrderProductViewModel): OrderRow => {
  return addOrderRowBase(product, {
    change: Change.REPLACED,
    discount: undefined,
  });
};

export const mapOrderRowFromReplacementProduct = (product: AxfoodReplacementProductViewModel): OrderRow => {
  return addOrderRowBase(product as HybrisOrderProduct, {
    change: Change.REPLACEMENT,
  });
};
