import Paragraph from '@components/atoms/Paragraph';
import { Product } from '../ProductDataMapper/types';
import { ProductManufacturer, ProductManufacturerVolume } from '../ProductInformation/ProductInformation.styles';
import { StyledLinkArea } from './ProductTitle.styles';

interface Props {
  product: Product;
  href?: UrlType;
  as?: UrlType;
  disableLinks?: boolean;
  handleProductLinkClick?: () => void;
  linkAriaLabel: string;
}
const ProductTitle = ({ href, as, handleProductLinkClick, product, linkAriaLabel }: Props) => {
  const { manufacturer, displayVolume, name } = product;
  return (
    <>
      {!!href ? (
        <StyledLinkArea
          href={href}
          nextLinkAs={as}
          title={name}
          shallow
          onClick={handleProductLinkClick}
          scroll={false}
          aria-label={linkAriaLabel}
        >
          <Paragraph size="medium" weight="bold" color="black" truncateText data-testid="product-title">
            {name}
          </Paragraph>
        </StyledLinkArea>
      ) : (
        <Paragraph size="medium" weight="bold" color="black" truncateText data-testid="product-title">
          {name}
        </Paragraph>
      )}
      <ProductManufacturerVolume>
        <Paragraph size="xsmall" weight="medium">
          {manufacturer && (
            <ProductManufacturer data-testid="display-manufacturer">
              {`${manufacturer}${displayVolume ? ', ' : ''}`}
            </ProductManufacturer>
          )}
          {!!displayVolume && <span data-testid="display-volume">{`${displayVolume}`}</span>}
        </Paragraph>
      </ProductManufacturerVolume>
    </>
  );
};

export default ProductTitle;
