import { focusBoxShadow } from '@styles/utils';
import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-visible {
    ${focusBoxShadow}
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const removeWhenNextDefined = () => false;
