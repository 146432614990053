import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledDialog = styled.dialog<{ $transparent: boolean }>`
  background-color: ${({ $transparent, theme }) => ($transparent ? 'transparent' : theme.colors.colorWhite)};
  border: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  scroll-behavior: smooth;

  ${media.desktop} {
    top: ${rem(50)};
    width: fit-content;
    max-width: unset;
    height: revert;
    max-height: calc(100vh - ${rem(100)});
    margin: 0 auto;
  }

  &::backdrop {
    background-color: ${({ theme }) => theme.colors.colorAcadia};
    opacity: 0.5;
  }
  &:open {
    body {
      overflow-y: hidden;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: ${rem(10)};
  top: ${rem(10)};
  margin: ${rem(6)} ${rem(8)};
  cursor: pointer;
`;
