import Modal from '@components/molecules/__DEPRECATED__/Modal/index';
import useResponsive from '@hooks/useResponsive';
import VideoIframe from './VideoIframe';

export interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videoLink: string;
}

const VideoModal = ({ open, onClose, videoLink }: VideoModalProps) => {
  const { isDesktopOrGreater, isTabletPortraitOrGreater } = useResponsive();
  return (
    <Modal
      size={isDesktopOrGreater ? 'large' : 'small'}
      isOpen={open}
      darkBgShadow
      hideBackground={isTabletPortraitOrGreater}
      withCloseButton={!isTabletPortraitOrGreater}
      withPadding={false}
      closeHandler={onClose}
    >
      <VideoIframe isModal videoLink={videoLink} autoplay />
    </Modal>
  );
};

export default VideoModal;
