import Icon, { IconType } from '@components/atoms/Icon/Icon';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import DELIVERY_METHODS from '@constants/deliveryMethods';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { Content, DeliveryHeaderText, DeliveryIconWrapper, StyledButton } from '../DeliveryButton/DeliveryButton.styles';

export const RedIconWithTextContent = ({
  icon,
  text,
  mobile = false,
  slotSelected,
  deliveryText = '',
  isDelivery = false,
  deliveryMode = '',
}: {
  icon: IconType;
  text: string;
  mobile?: boolean;
  slotSelected?: string;
  deliveryText?: string;
  isDelivery?: boolean;
  deliveryMode?: string;
}) => {
  const { t } = useTranslation('header');
  const pickSlotTimeCopy =
    deliveryMode === DELIVERY_METHODS.PICKUP_CODE ? 'delivery->pickSlotTimePickUp' : 'delivery->pickSlotTimeDelivery';
  const slotNotSelectForMethod = !slotSelected && deliveryMode;
  return (
    <Content $mobile={mobile}>
      {!deliveryMode && (
        <DeliveryIconWrapper>
          <Icon icon={icon} color={'red'} />
        </DeliveryIconWrapper>
      )}

      {!mobile && slotSelected && (
        <DeliveryHeaderText textColor={slotSelected ? 'black' : 'primary'} size={'sm'}>
          {t(deliveryText)} {isDelivery && t('header:delivery->reserved')}
        </DeliveryHeaderText>
      )}
      {!mobile && slotNotSelectForMethod && (
        <DeliveryHeaderText textColor="black" size={'sm'}>
          {t(deliveryText)}
        </DeliveryHeaderText>
      )}
      {mobile ? (
        <Paragraph textColor={'primary'} size={'sm'}>
          {text}
        </Paragraph>
      ) : (
        <Paragraph textColor={slotSelected || deliveryMode ? 'black' : 'primary'} size={'md'}>
          {slotNotSelectForMethod ? t(pickSlotTimeCopy) : text}
        </Paragraph>
      )}
    </Content>
  );
};

const SecondaryHeaderButton = ({
  children,
  onClick,
  mobile = false,
  deliveryModeSelected = '',
  modalIsOpen = false,
  'aria-expanded': ariaExpanded,
  'aria-label': ariaLabel,
  'aria-haspopup': ariaHasPopup,
}: {
  children: ReactNode;
  onClick?: () => void;
  mobile?: boolean;
  deliveryModeSelected?: string;
  modalIsOpen?: boolean;
  'aria-expanded'?: boolean;
  'aria-label'?: string;
  'aria-haspopup'?: 'dialog' | boolean;
}) => (
  <StyledButton
    aria-expanded={ariaExpanded}
    onClick={onClick}
    $mobile={mobile}
    variant="secondary"
    $deliveryModeSelected={deliveryModeSelected}
    $modalIsOpen={modalIsOpen}
    aria-label={ariaLabel}
    aria-haspopup={ariaHasPopup}
  >
    {children}
  </StyledButton>
);

export default SecondaryHeaderButton;
