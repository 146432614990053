import isFrontend from '@helpers/isFrontend';
import LockScrollStyle from '@styles/lockScroll';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Container } from './Takeover.styles';

// This is the takeover component, it adds the basic styling for all takeovers.
// It is meant to be used as a foundation container component for composing more
// context specific takeovers. "CreatePortal" is here used to displace the component
// from it's normal DOM-position to instead be rendered in the "takeover-container"
// in Layout.tsx.
interface TakeoverProps {
  children: ReactNode;
}

const Takeover = ({ children }: TakeoverProps) => {
  const takeoverPortalContainer = isFrontend() ? document.getElementById('takeover-container') : null;

  return isFrontend() ? (
    ReactDOM.createPortal(
      <Container role="dialog" data-testid="takeover-menu">
        <LockScrollStyle />
        {children}
      </Container>,
      takeoverPortalContainer as HTMLElement
    )
  ) : (
    <Container>{children}</Container>
  );
};

export default Takeover;
