import media from '@constants/responsive';
import { focusBoxShadow } from '@styles/utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { VariantType } from './Tab';

interface TabProps {
  $variant?: VariantType;
  $active: boolean;
  disabled?: boolean;
  $withTooltip?: boolean;
}

export const TabTooltipContent = styled.span`
  opacity: 0;
  pointer-events: none;
  padding: ${rem(15)};
  background-color: ${({ theme }) => theme.colors.colorCararra};
  width: ${rem(330)};
  border-radius: ${rem(6)};
  line-height: 1.5;
`;

export const TooltipContent = styled.span`
  opacity: 0;
  pointer-events: none;
  padding: ${rem(15)};
  background-color: ${({ theme }) => theme.colors.colorCararra};
  width: ${rem(330)};
  border-radius: ${rem(6)};
  line-height: 1.5;
`;

export const TabButton = styled.button<TabProps>`
  margin: 0 ${rem(20)};
  line-height: ${rem(25)};
  cursor: pointer;
  border: none;
  background-color: inherit;
  padding-bottom: ${rem(3)};

  &:focus-visible {
    ${focusBoxShadow}
  }

  ${(props) =>
    props.$active &&
    css`
      border-bottom: solid ${props.theme.colors.colorPrimary};
      padding-bottom: 0;
    `}

  ${(props) =>
    props.$variant === 'filled' &&
    css`
      background: ${props.theme.colors.colorCararra};
      width: 49.5%;
      margin: ${rem(45)} 0 0;
      height: ${rem(56)};
      font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
      font-size: ${rem(14)};
      display: flex;
      align-items: center;
      justify-content: center;
    `}

    ${(props) =>
    props.$variant === 'filled' &&
    props.$active &&
    css`
      border-bottom: 0;
      background: ${props.theme.colors.colorPrimary};
      color: ${props.theme.colors.colorWhite};
      position: relative;
      &::after {
        position: absolute;
        bottom: ${rem(-6)};
        left: 50%;
        width: 0;
        height: 0;
        border-left: ${rem(10)} solid transparent;
        border-right: ${rem(10)} solid transparent;
        border-top: ${rem(7)} solid ${props.theme.colors.colorPrimary};
        content: ' ';
      }
    `}

    ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.colors.colorDesertStorm};
      color: ${props.theme.colors.colorSilver};
      cursor: default;
    `}


  ${(props) =>
    props.$variant === 'paged' &&
    css`
      position: relative;
      top: ${rem(1)};
      background: ${props.theme.colors.colorCararra};
      width: 49.5%;
      margin: 0 0;
      height: ${rem(56)};
      font-size: ${rem(14)};
      display: flex;
      align-items: center;
      justify-content: center;
    `}

  ${(props) =>
    props.$variant === 'paged' &&
    props.$active &&
    css`
      border-left: ${rem(1)} solid ${props.theme.colors.colorCararra};
      border-right: ${rem(1)} solid ${props.theme.colors.colorCararra};
      border-top: ${rem(1)} solid ${props.theme.colors.colorCararra};
      border-bottom: none;
      background-color: ${props.theme.colors.colorWhite};
    `}

    ${({ $withTooltip }) =>
    $withTooltip &&
    `
    width: 100%;
    cursor: context-menu;
    display: inline-flex;
    &:hover + ${TooltipContent} {
      opacity: 0.9;
      z-index: 10;
      pointer-events: auto;
    }
    `}
`;

export const IconWrapper = styled.span`
  margin-right: ${rem(10)};
`;

export const TabText = styled.div<TabProps>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.$variant === 'default' &&
    `
    font-size: ${rem(16)};
    font-weight: 700;
    ${media.tabletPortrait} {
      font-size: ${rem(20)};
    }
  `}
  ${(props) =>
    props.$variant === 'paged' &&
    props.$active &&
    css`
      border-bottom: solid ${props.theme.colors.colorPrimary};
      padding-bottom: 0;
    `}

  ${(props) =>
    props.$variant === 'filled' &&
    props.$active &&
    css`
      margin-bottom: ${rem(3)};
    `}
`;

export const TabTooltipWrapper = styled.div`
  flex: 1;
  position: relative;
  margin: 0 ${rem(4)};
`;
export const TooltipIconWrapper = styled.div`
  display: flex;
  margin-left: ${rem(4)};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  font-weight: normal;
  font-size: ${rem(16)};
`;
