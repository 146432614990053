import { Count } from '@components/atoms/Badge/Badge.styles';
import { memo } from 'react';
import styled from 'styled-components';
import HeaderButton, { Props as ButtonProps } from './HeaderButton';

export const Container = styled.div`
  position: relative;
`;

type PickedProps = Pick<
  ButtonProps,
  'onClick' | 'aria-label' | 'aria-expanded' | 'children' | 'variant' | 'borderRadius' | 'href' | 'aria-haspopup'
>;

interface Props extends PickedProps {
  badgeValue: number;
}

const NotificationButton = ({
  'aria-expanded': ariaExpanded,
  onClick,
  badgeValue,
  'aria-label': ariaLabel,
  children,
  variant,
  borderRadius,
  href,
  'aria-haspopup': ariaHasPopup,
}: Props) => {
  return (
    <Container>
      <HeaderButton
        aria-label={ariaLabel}
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        variant={variant}
        borderRadius={borderRadius}
        onClick={onClick}
        href={href}
      >
        {children}
      </HeaderButton>
      {!!badgeValue && (
        <Count
          data-testid="badge-value"
          $color={variant === 'secondary' ? 'red' : 'black'}
          $offsetHorizontal={-10}
          $offsetVertical={5}
        >
          {badgeValue}
        </Count>
      )}
    </Container>
  );
};

export default memo(NotificationButton);
