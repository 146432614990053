import Button from '@components/atoms/Button/Button';
import Icon from '@components/atoms/Icon';
import Overlay from '@components/atoms/Overlay';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useOpenDeliveryModal from '@components/organisms/Header/hooks/useOpenDeliveryModal/useOpenDeliveryModal';
import { formatAddress } from '@helpers/address';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import PickUpInStoreIcon from '@public/icons/regularIcons/icon-butik.svg';
import HomeDeliveryIcon from '@public/icons/regularIcons/icon-delivery.svg';
import { selectCart } from '@selectors/cart';
import { selectActiveStoreAddress, selectActiveStoreName } from '@selectors/delivery';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import {
  ButtonWrapper,
  DeliveryInformationWrapper,
  IconWrapper,
  InformationHeader,
  InformationWrapper,
  ReservationInformation,
  SlotDeliveryInformation,
} from './DeliveryInformationModal.styles';

interface Props {
  popperReference: HTMLElement | null;
  onClick: () => void;
  mobile?: boolean;
}

const DeliveryInformationModal = ({ popperReference, onClick, mobile = false }: Props) => {
  const { t } = useTranslation('header');
  const [popperElement, setPopperElement] = useState(null);
  const { isAnyB2BCustomer } = useCustomer();
  const cart = useAppSelector(selectCart);
  const deliveryModeCode = cart.deliveryModeCode;
  const activeStoreAddress = useAppSelector(selectActiveStoreAddress);
  const activeStoreName = useAppSelector(selectActiveStoreName);
  const { openDeliveryModal } = useOpenDeliveryModal();
  const popperTopOffset = mobile ? 0 : 12;
  const deliveryAddress = cart.deliveryAddress;

  const fullAddress = formatAddress(deliveryAddress?.line1, deliveryAddress?.postalCode, deliveryAddress?.town);
  const storeAddress = formatAddress(
    activeStoreAddress?.line1,
    activeStoreAddress?.postalCode,
    activeStoreAddress?.town
  );

  const popperReferenceBottomPosition = popperReference
    ? popperReference?.getClientRects()[0]?.bottom + popperTopOffset
    : 0;

  const deliveryHeader =
    deliveryModeCode === 'pickUpInStore'
      ? 'delivery->pickUp'
      : isAnyB2BCustomer
        ? 'delivery->delivery'
        : 'delivery->homeDelivery';

  const isPickUpInStore = deliveryModeCode === 'pickUpInStore';
  const { styles, attributes } = usePopper(popperReference, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, popperTopOffset],
        },
      },
    ],
  });

  const pickSlotTimeCopy =
    !cart.slot?.formattedTime && isPickUpInStore ? 'delivery->pickSlotTimePickUp' : 'delivery->pickSlotTimeDelivery';

  return (
    <>
      <DeliveryInformationWrapper
        ref={setPopperElement as any}
        style={styles.popper}
        {...attributes.popper}
        data-testid="delivery-information-modal"
        role="dialog"
        aria-label={t(deliveryHeader)}
      >
        <InformationHeader>
          <IconWrapper>
            <Icon icon={isPickUpInStore ? PickUpInStoreIcon : HomeDeliveryIcon} color="red" />
          </IconWrapper>

          <Paragraph textColor="primary" bold="bold" size="md">
            {`${t(deliveryHeader)} `}
            {isPickUpInStore ? activeStoreName : cart.slot?.formattedTime && t('delivery->reserved')}
          </Paragraph>
        </InformationHeader>
        <SlotDeliveryInformation>
          <InformationWrapper>
            <Paragraph size="content-text" bold="bold">
              <>{cart.slot?.formattedTime ? cart.slot.formattedTime : t(pickSlotTimeCopy)}</>
            </Paragraph>
            <Paragraph bold="medium">{isPickUpInStore ? storeAddress : fullAddress}</Paragraph>
          </InformationWrapper>
          <ButtonWrapper>
            <Button onClick={openDeliveryModal} variant="grey" aria-haspopup="dialog">
              {t('common:defaultActions->edit')}
            </Button>
          </ButtonWrapper>
        </SlotDeliveryInformation>
        <ReservationInformation>
          <Paragraph size="md" bold="bold">
            {t('delivery->timeLimit')}
          </Paragraph>
          {cart?.slot?.formattedTime && (
            <Paragraph size="md">{t('delivery->closeTime', { closeTime: cart?.slot?.closeTimeFormatted })}</Paragraph>
          )}
        </ReservationInformation>
      </DeliveryInformationWrapper>
      <Overlay opacity={0.5} onClick={onClick} zIndex={99} lockScroll={true} position={popperReferenceBottomPosition} />
    </>
  );
};

export default DeliveryInformationModal;
