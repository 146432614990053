import LinkArea from '@components/atoms/LinkArea';
import { rem } from 'polished';
import styled from 'styled-components';

export const SocialMediaIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(8)};
`;

export const IconWrapper = styled.div`
  line-height: ${rem(1)};
`;
export const FlexLinkArea = styled(LinkArea)`
  display: flex;
`;
