import FooterHeading from '@components/molecules/FooterHeading';
import IconFacebook from '@public/icons/regularIcons/icon-social-facebook-mono.svg';
import IconInstagram from '@public/icons/regularIcons/icon-social-instagram-mono.svg';
import IconTiktok from '@public/icons/regularIcons/icon-social-tiktok.svg';
import IconYoutube from '@public/icons/regularIcons/icon-social-youtube-mono.svg';

import { Stack } from '@components/atoms/Stack';
import { navigationClickTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { FlexLinkArea, IconWrapper, SocialMediaIcons } from './FooterSocialMediaIcons.styles';

const getIcon = (name: string) =>
  ({
    youtube: <IconYoutube width={46} height={32} data-testid="icon-youtube" />,
    facebook: <IconFacebook width={32} height={32} data-testid="icon-facebook" />,
    instagram: <IconInstagram width={32} height={32} data-testid="icon-instagram" />,
    tiktok: <IconTiktok width={32} height={32} data-testid="icon-tiktok" />,
  })[name.toLowerCase()] || null;

interface SocialMediaLinkIconProps {
  url: CMSLinkComponentType['url'];
  linkName: CMSLinkComponentType['linkName'];
}

const SocialMediaLinkIcon = memo(({ url, linkName }: SocialMediaLinkIconProps) => (
  <FlexLinkArea
    href={url}
    target="_blank"
    aria-label={linkName}
    onClick={() => navigationClickTracker.linkClickTracker('footer', linkName)}
  >
    {getIcon(linkName) || linkName}
  </FlexLinkArea>
));
SocialMediaLinkIcon.displayName = 'SocialMediaLinkIcon';

interface FooterSocialMediaProps {
  socialLinks: CMSLinkComponentType[];
}

const FooterSocialMediaIcons = ({ socialLinks }: FooterSocialMediaProps) => {
  const { t } = useTranslation('footer');

  const linksWithIcon = socialLinks.map(({ url, linkName }) => (
    <IconWrapper key={linkName}>
      <SocialMediaLinkIcon url={url} linkName={linkName} />
    </IconWrapper>
  ));
  return (
    <Stack data-testid="social-media-links" $space="xsmall">
      <FooterHeading text={t('footer->headers->followUs')} />
      <SocialMediaIcons>{linksWithIcon}</SocialMediaIcons>
    </Stack>
  );
};

export default memo(FooterSocialMediaIcons);
