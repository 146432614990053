import { useEffect } from 'react';
import { CloseButton, StyledDialog } from './DialogModal.styles';
import IconCloseBold from '@public/icons/regularIcons/icon-close.svg';
import { useDialog } from './useDialog';
import RoundIconButton from '@components/molecules/RoundIconButton';
import useTranslation from 'next-translate/useTranslation';
import LockScrollStyle from '@styles/lockScroll';

export interface DialogModalProps {
  children: React.ReactNode;
  onClose?: (arg: boolean) => void;
  showCloseButton?: boolean;
  scrollToPosition?: number;
  transparentBackground?: boolean;
}

const DialogModal = ({
  onClose,
  children,
  showCloseButton = true,
  scrollToPosition = 0,
  transparentBackground = false,
}: DialogModalProps) => {
  const { dialogRef, closeDialog, openDialog, scrollPosition, handleKeyDown } = useDialog(onClose);
  const { t } = useTranslation('common');

  useEffect(() => {
    openDialog();
    if (scrollToPosition) {
      scrollPosition(scrollToPosition);
    }
  }, []);

  return (
    <>
      <LockScrollStyle />
      <StyledDialog
        ref={dialogRef}
        $transparent={transparentBackground}
        data-testid="dialog-modal"
        onKeyDown={handleKeyDown}
      >
        {showCloseButton && (
          <CloseButton onClick={closeDialog}>
            <RoundIconButton
              icon={IconCloseBold}
              iconSize={40}
              onClick={closeDialog}
              buttonVariant="grey"
              color="white"
              aria-label={t('defaultActions->close')}
            />
          </CloseButton>
        )}
        {children}
      </StyledDialog>
    </>
  );
};

export default DialogModal;
