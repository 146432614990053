import { rem } from 'polished';
import { DefaultTheme, css } from 'styled-components';

/**
 * @summary turns a css color into the correct colors from hemköp theme, eg "red" -> "#EE1C2E"
 * @param color
 * @param theme
 */
export const getThemeColor = (color: string | ColorType, theme: DefaultTheme): string => {
  switch (color) {
    case 'black':
      return theme.colors.colorTextPrimary;
    case 'red':
      return theme.colors.colorPrimary;
    case 'grey':
      return theme.colors.colorTextSecondary;
    case 'white':
      return theme.colors.colorWhite;
    case 'yellow':
      return theme.colors.colorAmber;
    case 'pink':
    case 'blue':
      // blue case was requested by content editors to support old content in angular
      return theme.colors.colorHighlight;
    default:
      return color;
  }
};

// Hide element visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const focusBoxShadow = css`
  outline: none;
  box-shadow: 0 0 0 ${rem(2)} ${({ theme }) => theme.colors.colorFocus};
`;

export const insetFocusBoxShadow = css`
  outline: none;
  box-shadow: inset 0 0 0 ${rem(2)} ${({ theme }) => theme.colors.colorFocus};
`;
