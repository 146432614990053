import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook to manage an `AbortController` in React.
 *
 * This hook provides an `AbortSignal` that can be used in fetch requests or other
 * asynchronous operations to allow them to be aborted if the component unmounts
 * or if an abort is otherwise needed.
 */
function useAbortController() {
  const abortControllerRef = useRef<AbortController | null>(null);

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      // eslint-disable-next-line fp/no-mutation
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  const getSignal = useCallback(() => getAbortController().signal, [getAbortController]);

  useEffect(() => {
    return () => getAbortController().abort();
  }, [getAbortController]);

  return getSignal;
}

export default useAbortController;
