/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@components/atoms/Icon';
import IconHelp from '@public/icons/regularIcons/icon-help.svg';
import React, { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import { IconWrapper, TabButton, TabText, TabTooltipWrapper, TooltipContent, TooltipIconWrapper } from './Tab.styles';
import { TabType } from './types';

export declare type VariantType = 'default' | 'filled' | 'paged';

export interface TabProps {
  variant?: VariantType;
  id: string;
  tabName: string;
  isActive?: boolean;
  onClick: (id: string) => void;
  icon?: React.FC;
  disabled?: boolean;
  tooltip?: TabType['tooltip'];
  'data-test-id'?: string;
}
type TooltipProps = {
  children: ReactNode;
  tooltip: TabType['tooltip'];
};

const TabWithTooltip = ({ tooltip, children }: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-75, 0],
        },
      },
    ],
  });

  return (
    <TabTooltipWrapper ref={setReferenceElement as any}>
      {children}
      <TooltipContent
        ref={setPopperElement as any}
        style={styles.popper}
        {...attributes.popper}
        data-testid="tab-tooltip"
      >
        {tooltip ? tooltip.text : ''}
      </TooltipContent>
    </TabTooltipWrapper>
  );
};

const Tab = ({
  variant = 'default',
  isActive = false,
  icon = undefined,
  id,
  tabName,
  onClick,
  disabled,
  tooltip,
  'data-test-id': dataTestId,
}: TabProps) => {
  const handleOnClick = () => {
    onClick(id);
  };
  const color = isActive ? 'white' : 'black';
  const iconColor = disabled ? 'grey' : color;

  if (tooltip) {
    return (
      <TabWithTooltip tooltip={tooltip}>
        <TabButton
          data-testid={dataTestId || 'tab'}
          $variant={variant}
          $active={isActive}
          type="button"
          onClick={handleOnClick}
          disabled={disabled}
          $withTooltip
        >
          {icon && (
            <IconWrapper>
              <Icon icon={icon} color={iconColor} />
            </IconWrapper>
          )}
          <TabText $variant={variant} $active={isActive}>
            {tabName}
            <TooltipIconWrapper>
              <Icon icon={IconHelp} color={iconColor} data-testid="icon-help" />
            </TooltipIconWrapper>
          </TabText>
        </TabButton>
      </TabWithTooltip>
    );
  }
  return (
    <TabButton
      data-testid={dataTestId || 'tab'}
      $variant={variant}
      $active={isActive}
      type="button"
      onClick={handleOnClick}
      disabled={disabled}
    >
      {icon && (
        <IconWrapper>
          <Icon icon={icon} color={iconColor} />
        </IconWrapper>
      )}
      <TabText $variant={variant} $active={isActive}>
        {tabName}
      </TabText>
    </TabButton>
  );
};

export default Tab;
