import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ColumnPanel = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  ${media.tabletPortrait} {
    gap: ${rem(24)};
  }
`;

export const ContentWrapper = styled(ColumnPanel)<{ $centerContent: boolean; $fixedGap: boolean }>`
  ${({ $centerContent }) =>
    $centerContent
      ? css`
          align-items: center;
          text-align: center;
        `
      : css`
          align-items: start;
          text-align: start;
        `};
  ${({ $fixedGap }) =>
    $fixedGap
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: space-between;
        `};
`;

export const TextPanel = styled(ColumnPanel)<{ $onlyTitle: boolean }>`
  justify-content: start;

  ${({ $onlyTitle }) =>
    $onlyTitle &&
    `
      justify-content: center;
    `}
`;
