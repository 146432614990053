/* eslint-disable fp/no-mutation */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
export type ModalName = typeof CUSTOMER_REMINDER_MODAL;

export const CUSTOMER_REMINDER_MODAL = 'CUSTOMER_REMINDER_MODAL';
export interface ModalStoreState {
  openModal: ModalName | null;
  customerInfoReminder: {
    isMissingInfoReminderPending: boolean;
  };
}

const initialState: ModalStoreState = {
  openModal: null,
  customerInfoReminder: {
    isMissingInfoReminderPending: false,
  },
};

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openCustomerReminderModal(state) {
      state.openModal = CUSTOMER_REMINDER_MODAL;
    },
    closeModal(state, action: PayloadAction<ModalName>) {
      state.openModal = state.openModal === action.payload ? null : state.openModal;
    },
    setIsMissingInfoReminderPending(state, action: PayloadAction<boolean>) {
      state.customerInfoReminder.isMissingInfoReminderPending = action.payload;
    },
  },
});

export const { openCustomerReminderModal, closeModal, setIsMissingInfoReminderPending } = modalSlice.actions;

export const selectOpenModal = (state: RootState) => state.modals.openModal;

export const selectIsCustomerInfoReminderModalOpen = (state: RootState) =>
  state.modals.openModal === CUSTOMER_REMINDER_MODAL;

export const selectIsMissingInfoReminderPending = (state: RootState) =>
  state.modals.customerInfoReminder.isMissingInfoReminderPending;

export default modalSlice.reducer;
