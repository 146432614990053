/* eslint-disable fp/no-mutation */
import { useEffect, useRef } from 'react';

const useTimeout = (callback: () => void, ms: number) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  function startTimeout() {
    timer.current = setTimeout(() => {
      callback();
    }, ms);
  }

  function cancelTimeout() {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }

  useEffect(() => {
    return cancelTimeout; // Clear on unmount
  }, []);

  return {
    startTimeout,
    cancelTimeout,
  };
};

export default useTimeout;
