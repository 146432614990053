import * as notificationActions from '@actions/notification';
import Icon from '@components/atoms/Icon';
import AccountMenu from '@components/organisms/Header/AccountMenu';
import NOTIFICATIONS from '@constants/notifications';
import { useAppDispatch } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useResponsive from '@hooks/useResponsive';
import useShowMenu from '@hooks/useShowMenu';
import IconUser from '@public/icons/regularIcons/icon-user.svg';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useRef, useState } from 'react';
import NotificationButton from '../../../atoms/HeaderButton/NotificationButton';
import useAccountMenuItems from '../AccountMenu/useAccountMenuItems';
import AccountTakeoverMenu from '../AccountTakeoverMenu';
import useVoucherCount from '../hooks/useVoucherCount';
import { AccountButtonWrapper, Ellipsis } from './AccountButton.styles';

const AccountButton = ({ showAccountButtonText = true }: { showAccountButtonText?: boolean }) => {
  const { t } = useTranslation('header');
  const dispatch = useAppDispatch();
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const { isDesktopOrGreater } = useResponsive();

  const toggleAccountMenu = () => setShowAccountMenu(!showAccountMenu);
  const { voucherCount = 0 } = useVoucherCount();
  const { isAnyB2BCustomer, isLoggedIn, companyAlternativeName, companyName } = useCustomer();
  const { showDesktopMenu } = useShowMenu();
  const links = useAccountMenuItems();

  const getButtonText = () => {
    if (isAnyB2BCustomer) {
      return <Ellipsis> {companyAlternativeName || companyName}</Ellipsis>;
    } else if (isLoggedIn) {
      return t('header->account->toolbarAccountButton->myPages');
    } else {
      return t('header->account->toolbarAccountButton->logIn');
    }
  };

  useEffect(() => {
    if (voucherCount > 0) {
      dispatch(
        notificationActions.setNotificationVisibility({
          name: NOTIFICATIONS.VOUCHER_NOTIFICATION,
          count: true,
          show: true,
          number: voucherCount,
        })
      );
    }
  }, [voucherCount, dispatch]);

  const popperRef = useRef(null);
  useOnClickOutside(popperRef, () => {
    if (showDesktopMenu) {
      setShowAccountMenu(false);
    }
  });

  return (
    <AccountButtonWrapper ref={popperRef}>
      <NotificationButton
        aria-expanded={showAccountMenu}
        aria-label={t('common:ariaLabels->myPages')}
        aria-haspopup={showDesktopMenu ? true : 'dialog'}
        onClick={toggleAccountMenu}
        badgeValue={voucherCount}
        borderRadius={showAccountButtonText ? 32 : 16}
        variant={'secondary'}
      >
        <Icon color="black" icon={IconUser} />
        {showAccountButtonText && getButtonText()}
      </NotificationButton>
      {showAccountMenu && isDesktopOrGreater && (
        <AccountMenu onClose={toggleAccountMenu} onLinkClicked={toggleAccountMenu} popperReference={popperRef.current} />
      )}
      <AccountTakeoverMenu
        links={links}
        isOpen={showAccountMenu && !showDesktopMenu}
        onLinkClicked={toggleAccountMenu}
      />
    </AccountButtonWrapper>
  );
};
export default AccountButton;
