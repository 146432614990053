import Link from '@components/atoms/__DEPRECATED__/Link';
import { rem } from 'polished';
import styled from 'styled-components';

interface ContainerProps {
  $withBottomBorder: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  min-height: ${rem(110)};
  box-sizing: border-box;
  padding: ${rem(8)} ${rem(10)};
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorBorder};
  ${(props) =>
    !props.$withBottomBorder &&
    `
    border-bottom: 0
  `}
`;

interface ThumbnailWrapperProps {
  $withThumbnail: boolean;
}

export const ThumbnailWrapper = styled.div<ThumbnailWrapperProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: ${rem(78)};

  ${(props) =>
    !props.$withThumbnail &&
    `
    background: ${props.theme.colors.colorBorder};
  `}
`;

export const Thumbnail = styled.img`
  cursor: pointer;
  min-width: ${rem(61)};
  height: ${rem(61)};
  object-fit: cover;
`;

export const ProductInfo = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-left: ${rem(15)};
  display: flex;
  flex-direction: column;
`;

export const ProductInfoTopRow = styled.div`
  padding: ${rem(5)} 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ProductTitles = styled.div`
  cursor: pointer;
`;

export const ProductTitle = styled.div`
  max-width: ${rem(250)};
  height: ${rem(24)};
  white-space: nowrap;

  a {
    &:hover {
      color: ${({ theme }) => theme.colors.colorTextPrimary};
    }
  }
`;

export const ProductInfoBottomRow = styled.div`
  padding: ${rem(5)} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuantityWrapper = styled.div`
  width: ${rem(140)};
`;

export const PriceDepositWrapper = styled.div`
  text-align: right;
`;

export const StyledLink = styled(Link)`
  display: block;
`;
