import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Modal from '@components/molecules/__DEPRECATED__/Modal/index';
import isFrontend from '@helpers/isFrontend';
import useTranslation from 'next-translate/useTranslation';
import ReactDOM from 'react-dom';
import { Buttons, ButtonWrapper, ConfirmModalWrapper, TextWrapper } from './ConfirmModal.styles';

export interface Props {
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmText?: string;
  isLoading?: boolean;
}

const ConfirmModal = ({ onConfirm, onClose, confirmButtonText, cancelButtonText, confirmText, isLoading }: Props) => {
  const { t } = useTranslation('common');
  const modalContainer = isFrontend() ? document.getElementById('modal-container') : null;

  if (!isFrontend()) {
    return null;
  }
  const text = confirmText || t('minicart:confirmModal->title');

  return ReactDOM.createPortal(
    <>
      <Modal size="small" isOpen closeHandler={onClose} lockModalScroll={false} data-testid="confirm-modal">
        <ConfirmModalWrapper>
          <TextWrapper>
            <Paragraph>{text}</Paragraph>
          </TextWrapper>
          <Buttons>
            <ButtonWrapper>
              <Button variant="secondary" onClick={onClose} aria-label="cancel">
                {cancelButtonText || t('common:defaultActions->cancel')}
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button variant="highlight" onClick={onConfirm} aria-label="confirm" isLoading={isLoading}>
                {confirmButtonText || t('common:defaultActions->delete')}
              </Button>
            </ButtonWrapper>
          </Buttons>
        </ConfirmModalWrapper>
      </Modal>
    </>,
    modalContainer as HTMLElement
  );
};

export default ConfirmModal;
