import media from '@constants/responsive';
import { focusBoxShadow } from '@styles/utils';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

export interface IRoundButton {
  $buttonVariant: 'none' | 'grey' | 'white';
}
export const RoundButton = styled.button<IRoundButton>`
  cursor: pointer;
  background: none;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  &:focus-visible {
    ${focusBoxShadow}
  }

  ${media.tabletPortrait} {
    width: auto;
  }

  ${(props) =>
    props.$buttonVariant === 'grey' &&
    css`
      background: ${({ theme }) => theme.colors.colorStack};

      &:focus {
        background: ${({ theme }) => darken(0.2, theme.colors.colorStack)};
      }
      @media (hover: hover) {
        &:hover {
          background: ${({ theme }) => darken(0.1, theme.colors.colorStack)};
        }
      }
    `}

  ${(props) =>
    props.$buttonVariant === 'white' &&
    css`
      background: ${({ theme }) => theme.colors.colorWhite};

      &:focus {
        background: ${({ theme }) => darken(0.2, theme.colors.colorWhite)};
      }
      @media (hover: hover) {
        &:hover {
          background: ${({ theme }) => darken(0.1, theme.colors.colorWhite)};
        }
      }
    `}
`;
