/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodCartViewModel } from '../dto';
// @ts-ignore
import { AxfoodSlotsScheduleViewModel } from '../dto';
// @ts-ignore
import { TimeSlotData } from '../dto';
// @ts-ignore
import { TmsDeliveryWindowJson } from '../dto';
/**
 * SlotApi - axios parameter creator
 * @export
 */
export const SlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get home delivery slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeDeliverySlotsForDayUsingGET: async (fromDate: string, nrOfDays: number, postalCode?: string, b2b?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getHomeDeliverySlotsForDayUsingGET', 'fromDate', fromDate)
            // verify required parameter 'nrOfDays' is not null or undefined
            assertParamExists('getHomeDeliverySlotsForDayUsingGET', 'nrOfDays', nrOfDays)
            const localVarPath = `/axfood/rest/slot/homeDelivery/daysAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (nrOfDays !== undefined) {
                localVarQueryParameter['nrOfDays'] = nrOfDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get home delivery slots
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeDeliverySlotsUsingGET: async (postalCode?: string, b2b?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/slot/homeDelivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pick in store slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickInStoreSlotsForDayUsingGET: async (fromDate: string, nrOfDays: number, storeId?: string, b2b?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getPickInStoreSlotsForDayUsingGET', 'fromDate', fromDate)
            // verify required parameter 'nrOfDays' is not null or undefined
            assertParamExists('getPickInStoreSlotsForDayUsingGET', 'nrOfDays', nrOfDays)
            const localVarPath = `/axfood/rest/slot/pickInStore/daysAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (nrOfDays !== undefined) {
                localVarQueryParameter['nrOfDays'] = nrOfDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pick in store slots
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickInStoreSlotsUsingGET: async (storeId?: string, b2b?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/slot/pickInStore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (b2b !== undefined) {
                localVarQueryParameter['b2b'] = b2b;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get slot in cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotSetToCartUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/slot/slotInCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is current slot available for postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCurrentSlotAvailableForPostalCodeUsingGET: async (postalCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('isCurrentSlotAvailableForPostalCodeUsingGET', 'postalCode', postalCode)
            const localVarPath = `/axfood/rest/slot/currentSlotAvailableForPostalCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove slot from cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSlotFromCartUsingDELETE: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/slot/slotInCart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set slot to cart
         * @param {string} slotCode slotCode
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [isTmsSlot] isTmsSlot
         * @param {TmsDeliveryWindowJson} [tmsDeliveryWindowJson] tmsDeliveryWindowJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSlotToCartUsingPOST: async (slotCode: string, checkStock?: boolean, isTmsSlot?: boolean, tmsDeliveryWindowJson?: TmsDeliveryWindowJson, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slotCode' is not null or undefined
            assertParamExists('setSlotToCartUsingPOST', 'slotCode', slotCode)
            const localVarPath = `/axfood/rest/slot/slotInCart/{slotCode}`
                .replace(`{${"slotCode"}}`, encodeURIComponent(String(slotCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (checkStock !== undefined) {
                localVarQueryParameter['checkStock'] = checkStock;
            }

            if (isTmsSlot !== undefined) {
                localVarQueryParameter['isTmsSlot'] = isTmsSlot;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tmsDeliveryWindowJson, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlotApi - functional programming interface
 * @export
 */
export const SlotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SlotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get home delivery slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeDeliverySlotsForDayUsingGET(fromDate: string, nrOfDays: number, postalCode?: string, b2b?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodSlotsScheduleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeDeliverySlotsForDayUsingGET(fromDate, nrOfDays, postalCode, b2b, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get home delivery slots
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeDeliverySlotsUsingGET(postalCode?: string, b2b?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodSlotsScheduleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeDeliverySlotsUsingGET(postalCode, b2b, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pick in store slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickInStoreSlotsForDayUsingGET(fromDate: string, nrOfDays: number, storeId?: string, b2b?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodSlotsScheduleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickInStoreSlotsForDayUsingGET(fromDate, nrOfDays, storeId, b2b, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pick in store slots
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPickInStoreSlotsUsingGET(storeId?: string, b2b?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodSlotsScheduleViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPickInStoreSlotsUsingGET(storeId, b2b, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get slot in cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlotSetToCartUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlotSetToCartUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is current slot available for postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isCurrentSlotAvailableForPostalCodeUsingGET(postalCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isCurrentSlotAvailableForPostalCodeUsingGET(postalCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove slot from cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeSlotFromCartUsingDELETE(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeSlotFromCartUsingDELETE(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set slot to cart
         * @param {string} slotCode slotCode
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [isTmsSlot] isTmsSlot
         * @param {TmsDeliveryWindowJson} [tmsDeliveryWindowJson] tmsDeliveryWindowJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setSlotToCartUsingPOST(slotCode: string, checkStock?: boolean, isTmsSlot?: boolean, tmsDeliveryWindowJson?: TmsDeliveryWindowJson, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCartViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setSlotToCartUsingPOST(slotCode, checkStock, isTmsSlot, tmsDeliveryWindowJson, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SlotApi - factory interface
 * @export
 */
export const SlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SlotApiFp(configuration)
    return {
        /**
         * 
         * @summary Get home delivery slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeDeliverySlotsForDayUsingGET(fromDate: string, nrOfDays: number, postalCode?: string, b2b?: boolean, options?: any): AxiosPromise<AxfoodSlotsScheduleViewModel> {
            return localVarFp.getHomeDeliverySlotsForDayUsingGET(fromDate, nrOfDays, postalCode, b2b, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get home delivery slots
         * @param {string} [postalCode] postalCode
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeDeliverySlotsUsingGET(postalCode?: string, b2b?: boolean, options?: any): AxiosPromise<AxfoodSlotsScheduleViewModel> {
            return localVarFp.getHomeDeliverySlotsUsingGET(postalCode, b2b, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pick in store slots for day
         * @param {string} fromDate fromDate
         * @param {number} nrOfDays nrOfDays
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickInStoreSlotsForDayUsingGET(fromDate: string, nrOfDays: number, storeId?: string, b2b?: boolean, options?: any): AxiosPromise<AxfoodSlotsScheduleViewModel> {
            return localVarFp.getPickInStoreSlotsForDayUsingGET(fromDate, nrOfDays, storeId, b2b, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pick in store slots
         * @param {string} [storeId] storeId
         * @param {boolean} [b2b] b2b
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPickInStoreSlotsUsingGET(storeId?: string, b2b?: boolean, options?: any): AxiosPromise<AxfoodSlotsScheduleViewModel> {
            return localVarFp.getPickInStoreSlotsUsingGET(storeId, b2b, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get slot in cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlotSetToCartUsingGET(options?: any): AxiosPromise<TimeSlotData> {
            return localVarFp.getSlotSetToCartUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is current slot available for postal code
         * @param {string} postalCode postalCode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isCurrentSlotAvailableForPostalCodeUsingGET(postalCode: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isCurrentSlotAvailableForPostalCodeUsingGET(postalCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove slot from cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSlotFromCartUsingDELETE(options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.removeSlotFromCartUsingDELETE(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set slot to cart
         * @param {string} slotCode slotCode
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [isTmsSlot] isTmsSlot
         * @param {TmsDeliveryWindowJson} [tmsDeliveryWindowJson] tmsDeliveryWindowJson
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSlotToCartUsingPOST(slotCode: string, checkStock?: boolean, isTmsSlot?: boolean, tmsDeliveryWindowJson?: TmsDeliveryWindowJson, options?: any): AxiosPromise<AxfoodCartViewModel> {
            return localVarFp.setSlotToCartUsingPOST(slotCode, checkStock, isTmsSlot, tmsDeliveryWindowJson, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SlotApi - object-oriented interface
 * @export
 * @class SlotApi
 * @extends {BaseAPI}
 */
export class SlotApi extends BaseAPI {
    /**
     * 
     * @summary Get home delivery slots for day
     * @param {string} fromDate fromDate
     * @param {number} nrOfDays nrOfDays
     * @param {string} [postalCode] postalCode
     * @param {boolean} [b2b] b2b
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getHomeDeliverySlotsForDayUsingGET(fromDate: string, nrOfDays: number, postalCode?: string, b2b?: boolean, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getHomeDeliverySlotsForDayUsingGET(fromDate, nrOfDays, postalCode, b2b, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get home delivery slots
     * @param {string} [postalCode] postalCode
     * @param {boolean} [b2b] b2b
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getHomeDeliverySlotsUsingGET(postalCode?: string, b2b?: boolean, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getHomeDeliverySlotsUsingGET(postalCode, b2b, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pick in store slots for day
     * @param {string} fromDate fromDate
     * @param {number} nrOfDays nrOfDays
     * @param {string} [storeId] storeId
     * @param {boolean} [b2b] b2b
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getPickInStoreSlotsForDayUsingGET(fromDate: string, nrOfDays: number, storeId?: string, b2b?: boolean, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getPickInStoreSlotsForDayUsingGET(fromDate, nrOfDays, storeId, b2b, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pick in store slots
     * @param {string} [storeId] storeId
     * @param {boolean} [b2b] b2b
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getPickInStoreSlotsUsingGET(storeId?: string, b2b?: boolean, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getPickInStoreSlotsUsingGET(storeId, b2b, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get slot in cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public getSlotSetToCartUsingGET(options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).getSlotSetToCartUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is current slot available for postal code
     * @param {string} postalCode postalCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public isCurrentSlotAvailableForPostalCodeUsingGET(postalCode: string, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).isCurrentSlotAvailableForPostalCodeUsingGET(postalCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove slot from cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public removeSlotFromCartUsingDELETE(options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).removeSlotFromCartUsingDELETE(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set slot to cart
     * @param {string} slotCode slotCode
     * @param {boolean} [checkStock] checkStock
     * @param {boolean} [isTmsSlot] isTmsSlot
     * @param {TmsDeliveryWindowJson} [tmsDeliveryWindowJson] tmsDeliveryWindowJson
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlotApi
     */
    public setSlotToCartUsingPOST(slotCode: string, checkStock?: boolean, isTmsSlot?: boolean, tmsDeliveryWindowJson?: TmsDeliveryWindowJson, options?: AxiosRequestConfig) {
        return SlotApiFp(this.configuration).setSlotToCartUsingPOST(slotCode, checkStock, isTmsSlot, tmsDeliveryWindowJson, options).then((request) => request(this.axios, this.basePath));
    }
}
