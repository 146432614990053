import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const colorMap = {
  BLOND_YELLOW: '#fceebb',
  DUSTY_GREEN: '#dbe3c9',
  ICE_BLUE: '#c6eafa',
  PALE_BLUE: '#e1eff3',
  LIGHT_RED: '#f8d2c5',
  WHEAT: '#f7d8b1',
  SUEDE: '#d8c7b9',
  DARK_VANILLA: '#d2b89b',
  EGGSHELL: '#f2e6d5',
  FOG_GRAY: '#d5d2c9',
  CHAMPAGNE_PINK: '#f8e1d6',
};

export const CategoryHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  min-height: ${rem(132)};

  ${media.tabletPortrait} {
    min-height: ${rem(267)};
    flex-direction: row;
  }
`;

export const CategoryHeroTextSection = styled.div<{
  $backgroundColor: keyof typeof colorMap;
  $hasShowMoreIcon: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${({ $backgroundColor, theme }) => colorMap[$backgroundColor] || theme.colors.colorWhite};
  border-radius: ${rem(16)};
  width: 100%;
  padding: ${rem(24)} ${rem(24)} ${({ $hasShowMoreIcon }) => ($hasShowMoreIcon ? rem(32) : rem(24))};
  min-height: ${rem(132)};

  ${media.tabletPortrait} {
    flex: 1;
    padding: ${rem(40)} ${rem(40)} ${rem(32)};
    width: 60%;
  }
`;

export const StyledHeading = styled(Heading)`
  max-width: ${rem(555)};
`;

export const StyledParagraph = styled(Paragraph)<{ $showMore: boolean }>`
  max-width: ${rem(555)};
  line-height: ${rem(20)};
  ${({ $showMore }) =>
    !$showMore &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    `}

  ${media.tabletPortrait} {
    line-height: ${rem(23)};
    -webkit-line-clamp: none;
  }
`;

export const ExpandableTextSection = styled.div`
  position: relative;
  overflow: hidden;
  height: auto;
  margin-top: ${rem(8)};

  ${media.tabletPortrait} {
    margin-top: ${rem(16)};
  }
`;

export const ExpandSectionIconWrapper = styled.div<{ $rotate: boolean }>`
  position: absolute;
  bottom: ${rem(8)};
  left: 50%;
  transform: translateX(-50%);
  transform: ${({ $rotate }) => ($rotate ? 'rotate(-90deg)' : 'rotate(90deg)')};
  cursor: pointer;
  display: block;

  ${media.tabletPortrait} {
    display: none;
  }
`;

export const CategoryHeroImageSection = styled.div<{ $image: string }>`
  display: none;
  width: 40%;
  border-radius: ${rem(16)};
  overflow: hidden;
  background-image: url(${({ $image }) => $image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${media.tabletPortrait} {
    display: block;
  }
`;
