/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodBasicStatusResponseViewModel } from '../dto';
// @ts-ignore
import { AxfoodStoreInfoViewModel } from '../dto';
// @ts-ignore
import { CartStoreChangeAllowed } from '../dto';
// @ts-ignore
import { FacetSearchPageData } from '../dto';
/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set active store in session
         * @param {string} storeId storeId
         * @param {boolean} activelySelected activelySelected
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [forceAsPickingStore] forceAsPickingStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStoreInSessionUsingPOST: async (storeId: string, activelySelected: boolean, checkStock?: boolean, forceAsPickingStore?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('activateStoreInSessionUsingPOST', 'storeId', storeId)
            // verify required parameter 'activelySelected' is not null or undefined
            assertParamExists('activateStoreInSessionUsingPOST', 'activelySelected', activelySelected)
            const localVarPath = `/axfood/rest/store/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (activelySelected !== undefined) {
                localVarQueryParameter['activelySelected'] = activelySelected;
            }

            if (checkStock !== undefined) {
                localVarQueryParameter['checkStock'] = checkStock;
            }

            if (forceAsPickingStore !== undefined) {
                localVarQueryParameter['forceAsPickingStore'] = forceAsPickingStore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is the given geopoint close to the given store
         * @param {string} storeId storeId
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfPointIsCloseToStoreUsingGET: async (storeId: string, latitude: number, longitude: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkIfPointIsCloseToStoreUsingGET', 'storeId', storeId)
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('checkIfPointIsCloseToStoreUsingGET', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('checkIfPointIsCloseToStoreUsingGET', 'longitude', longitude)
            const localVarPath = `/axfood/rest/store/{storeId}/pointClose`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is store permanently closed
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfStoreIsNotPermanentlyClosedUsingGET: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkIfStoreIsNotPermanentlyClosedUsingGET', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/isPermanentlyClosed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get click and collect stores
         * @param {boolean} [online] online
         * @param {boolean} [isB2B] isB2B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClickAndCollectStoresUsingGET: async (online?: boolean, isB2B?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/store/clickcollect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (online !== undefined) {
                localVarQueryParameter['online'] = online;
            }

            if (isB2B !== undefined) {
                localVarQueryParameter['isB2B'] = isB2B;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current base store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentBaseStoreUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/store/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentStoreDetailsUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/storeDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Is Change Store Allowed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreChangeAllowedUsingGET: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/cart/store/change-allowed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingDELETE: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingDELETE', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingGET: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingGET', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingHEAD: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingHEAD', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingOPTIONS: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingOPTIONS', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPATCH: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingPATCH', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPOST: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingPOST', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPUT: async (storeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getStoreInformationUsingPUT', 'storeId', storeId)
            const localVarPath = `/axfood/rest/store/{storeId}`
                .replace(`{${"storeId"}}`, encodeURIComponent(String(storeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Searches for stores
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {boolean} [searchHasClickAndCollect] If true, only CnC stores will be returned
         * @param {boolean} [searchHasB2bClickAndCollect] If true, only CnC stores available for B2B will be returned
         * @param {boolean} [scanAndGo] If true, only ScanAndGo stores will be returned
         * @param {boolean} [externalPickupLocation] If true, only stores marked as external pickup location will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUsingGET: async (q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', searchHasClickAndCollect?: boolean, searchHasB2bClickAndCollect?: boolean, scanAndGo?: boolean, externalPickupLocation?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getStoreUsingGET', 'q', q)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getStoreUsingGET', 'sort', sort)
            const localVarPath = `/axfood/rest/search/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (show !== undefined) {
                localVarQueryParameter['show'] = show;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (searchHasClickAndCollect !== undefined) {
                localVarQueryParameter['searchHasClickAndCollect'] = searchHasClickAndCollect;
            }

            if (searchHasB2bClickAndCollect !== undefined) {
                localVarQueryParameter['searchHasB2bClickAndCollect'] = searchHasB2bClickAndCollect;
            }

            if (scanAndGo !== undefined) {
                localVarQueryParameter['scanAndGo'] = scanAndGo;
            }

            if (externalPickupLocation !== undefined) {
                localVarQueryParameter['externalPickupLocation'] = externalPickupLocation;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available stores
         * @param {boolean} [online] online
         * @param {boolean} [clickAndCollect] clickAndCollect
         * @param {boolean} [b2bClickAndCollect] b2bClickAndCollect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresUsingGET: async (online?: boolean, clickAndCollect?: boolean, b2bClickAndCollect?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (online !== undefined) {
                localVarQueryParameter['online'] = online;
            }

            if (clickAndCollect !== undefined) {
                localVarQueryParameter['clickAndCollect'] = clickAndCollect;
            }

            if (b2bClickAndCollect !== undefined) {
                localVarQueryParameter['b2bClickAndCollect'] = b2bClickAndCollect;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer suggested pickup store
         * @param {boolean} [defaultToBaseStore] defaultToBaseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedPickUpStoreUsingGET: async (defaultToBaseStore?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/store/suggested-pickup-store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (defaultToBaseStore !== undefined) {
                localVarQueryParameter['defaultToBaseStore'] = defaultToBaseStore;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set active store in session
         * @param {string} storeId storeId
         * @param {boolean} activelySelected activelySelected
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [forceAsPickingStore] forceAsPickingStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateStoreInSessionUsingPOST(storeId: string, activelySelected: boolean, checkStock?: boolean, forceAsPickingStore?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateStoreInSessionUsingPOST(storeId, activelySelected, checkStock, forceAsPickingStore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is the given geopoint close to the given store
         * @param {string} storeId storeId
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfPointIsCloseToStoreUsingGET(storeId: string, latitude: number, longitude: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodBasicStatusResponseViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfPointIsCloseToStoreUsingGET(storeId, latitude, longitude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is store permanently closed
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkIfStoreIsNotPermanentlyClosedUsingGET(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkIfStoreIsNotPermanentlyClosedUsingGET(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get click and collect stores
         * @param {boolean} [online] online
         * @param {boolean} [isB2B] isB2B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClickAndCollectStoresUsingGET(online?: boolean, isB2B?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodStoreInfoViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClickAndCollectStoresUsingGET(online, isB2B, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current base store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentBaseStoreUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentBaseStoreUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get current store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentStoreDetailsUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentStoreDetailsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Is Change Store Allowed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreChangeAllowedUsingGET(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartStoreChangeAllowed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreChangeAllowedUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingDELETE(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingDELETE(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingGET(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingGET(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingHEAD(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingHEAD(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingOPTIONS(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingOPTIONS(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingPATCH(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingPATCH(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingPOST(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingPOST(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreInformationUsingPUT(storeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreInformationUsingPUT(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Searches for stores
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {boolean} [searchHasClickAndCollect] If true, only CnC stores will be returned
         * @param {boolean} [searchHasB2bClickAndCollect] If true, only CnC stores available for B2B will be returned
         * @param {boolean} [scanAndGo] If true, only ScanAndGo stores will be returned
         * @param {boolean} [externalPickupLocation] If true, only stores marked as external pickup location will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', searchHasClickAndCollect?: boolean, searchHasB2bClickAndCollect?: boolean, scanAndGo?: boolean, externalPickupLocation?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacetSearchPageData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreUsingGET(q, sort, page, size, show, searchHasClickAndCollect, searchHasB2bClickAndCollect, scanAndGo, externalPickupLocation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available stores
         * @param {boolean} [online] online
         * @param {boolean} [clickAndCollect] clickAndCollect
         * @param {boolean} [b2bClickAndCollect] b2bClickAndCollect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoresUsingGET(online?: boolean, clickAndCollect?: boolean, b2bClickAndCollect?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AxfoodStoreInfoViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoresUsingGET(online, clickAndCollect, b2bClickAndCollect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer suggested pickup store
         * @param {boolean} [defaultToBaseStore] defaultToBaseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSuggestedPickUpStoreUsingGET(defaultToBaseStore?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodStoreInfoViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSuggestedPickUpStoreUsingGET(defaultToBaseStore, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Set active store in session
         * @param {string} storeId storeId
         * @param {boolean} activelySelected activelySelected
         * @param {boolean} [checkStock] checkStock
         * @param {boolean} [forceAsPickingStore] forceAsPickingStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateStoreInSessionUsingPOST(storeId: string, activelySelected: boolean, checkStock?: boolean, forceAsPickingStore?: boolean, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.activateStoreInSessionUsingPOST(storeId, activelySelected, checkStock, forceAsPickingStore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is the given geopoint close to the given store
         * @param {string} storeId storeId
         * @param {number} latitude latitude
         * @param {number} longitude longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfPointIsCloseToStoreUsingGET(storeId: string, latitude: number, longitude: number, options?: any): AxiosPromise<AxfoodBasicStatusResponseViewModel> {
            return localVarFp.checkIfPointIsCloseToStoreUsingGET(storeId, latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is store permanently closed
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkIfStoreIsNotPermanentlyClosedUsingGET(storeId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.checkIfStoreIsNotPermanentlyClosedUsingGET(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get click and collect stores
         * @param {boolean} [online] online
         * @param {boolean} [isB2B] isB2B
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClickAndCollectStoresUsingGET(online?: boolean, isB2B?: boolean, options?: any): AxiosPromise<Array<AxfoodStoreInfoViewModel>> {
            return localVarFp.getClickAndCollectStoresUsingGET(online, isB2B, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current base store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentBaseStoreUsingGET(options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getCurrentBaseStoreUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentStoreDetailsUsingGET(options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getCurrentStoreDetailsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Is Change Store Allowed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreChangeAllowedUsingGET(options?: any): AxiosPromise<CartStoreChangeAllowed> {
            return localVarFp.getStoreChangeAllowedUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingDELETE(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingDELETE(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingGET(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingGET(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingHEAD(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingHEAD(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingOPTIONS(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingOPTIONS(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPATCH(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingPATCH(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPOST(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingPOST(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get store information
         * @param {string} storeId storeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreInformationUsingPUT(storeId: string, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getStoreInformationUsingPUT(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Searches for stores
         * @param {string} q The search query
         * @param {string} sort Field to use for sorting the results
         * @param {number} [page] Current page index. Used for pagination
         * @param {number} [size] Size of a single page of results.
         * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
         * @param {boolean} [searchHasClickAndCollect] If true, only CnC stores will be returned
         * @param {boolean} [searchHasB2bClickAndCollect] If true, only CnC stores available for B2B will be returned
         * @param {boolean} [scanAndGo] If true, only ScanAndGo stores will be returned
         * @param {boolean} [externalPickupLocation] If true, only stores marked as external pickup location will be returned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', searchHasClickAndCollect?: boolean, searchHasB2bClickAndCollect?: boolean, scanAndGo?: boolean, externalPickupLocation?: boolean, options?: any): AxiosPromise<FacetSearchPageData> {
            return localVarFp.getStoreUsingGET(q, sort, page, size, show, searchHasClickAndCollect, searchHasB2bClickAndCollect, scanAndGo, externalPickupLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available stores
         * @param {boolean} [online] online
         * @param {boolean} [clickAndCollect] clickAndCollect
         * @param {boolean} [b2bClickAndCollect] b2bClickAndCollect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoresUsingGET(online?: boolean, clickAndCollect?: boolean, b2bClickAndCollect?: boolean, options?: any): AxiosPromise<Array<AxfoodStoreInfoViewModel>> {
            return localVarFp.getStoresUsingGET(online, clickAndCollect, b2bClickAndCollect, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer suggested pickup store
         * @param {boolean} [defaultToBaseStore] defaultToBaseStore
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuggestedPickUpStoreUsingGET(defaultToBaseStore?: boolean, options?: any): AxiosPromise<AxfoodStoreInfoViewModel> {
            return localVarFp.getSuggestedPickUpStoreUsingGET(defaultToBaseStore, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @summary Set active store in session
     * @param {string} storeId storeId
     * @param {boolean} activelySelected activelySelected
     * @param {boolean} [checkStock] checkStock
     * @param {boolean} [forceAsPickingStore] forceAsPickingStore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public activateStoreInSessionUsingPOST(storeId: string, activelySelected: boolean, checkStock?: boolean, forceAsPickingStore?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).activateStoreInSessionUsingPOST(storeId, activelySelected, checkStock, forceAsPickingStore, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is the given geopoint close to the given store
     * @param {string} storeId storeId
     * @param {number} latitude latitude
     * @param {number} longitude longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public checkIfPointIsCloseToStoreUsingGET(storeId: string, latitude: number, longitude: number, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).checkIfPointIsCloseToStoreUsingGET(storeId, latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is store permanently closed
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public checkIfStoreIsNotPermanentlyClosedUsingGET(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).checkIfStoreIsNotPermanentlyClosedUsingGET(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get click and collect stores
     * @param {boolean} [online] online
     * @param {boolean} [isB2B] isB2B
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getClickAndCollectStoresUsingGET(online?: boolean, isB2B?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getClickAndCollectStoresUsingGET(online, isB2B, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current base store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCurrentBaseStoreUsingGET(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCurrentBaseStoreUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current store details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getCurrentStoreDetailsUsingGET(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getCurrentStoreDetailsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Is Change Store Allowed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreChangeAllowedUsingGET(options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreChangeAllowedUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingDELETE(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingDELETE(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingGET(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingGET(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingHEAD(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingHEAD(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingOPTIONS(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingOPTIONS(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingPATCH(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingPATCH(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingPOST(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingPOST(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get store information
     * @param {string} storeId storeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreInformationUsingPUT(storeId: string, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreInformationUsingPUT(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Searches for stores
     * @param {string} q The search query
     * @param {string} sort Field to use for sorting the results
     * @param {number} [page] Current page index. Used for pagination
     * @param {number} [size] Size of a single page of results.
     * @param {'Page' | 'All'} [show] Whether to show all the stores or a page of results.
     * @param {boolean} [searchHasClickAndCollect] If true, only CnC stores will be returned
     * @param {boolean} [searchHasB2bClickAndCollect] If true, only CnC stores available for B2B will be returned
     * @param {boolean} [scanAndGo] If true, only ScanAndGo stores will be returned
     * @param {boolean} [externalPickupLocation] If true, only stores marked as external pickup location will be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreUsingGET(q: string, sort: string, page?: number, size?: number, show?: 'Page' | 'All', searchHasClickAndCollect?: boolean, searchHasB2bClickAndCollect?: boolean, scanAndGo?: boolean, externalPickupLocation?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoreUsingGET(q, sort, page, size, show, searchHasClickAndCollect, searchHasB2bClickAndCollect, scanAndGo, externalPickupLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available stores
     * @param {boolean} [online] online
     * @param {boolean} [clickAndCollect] clickAndCollect
     * @param {boolean} [b2bClickAndCollect] b2bClickAndCollect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoresUsingGET(online?: boolean, clickAndCollect?: boolean, b2bClickAndCollect?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getStoresUsingGET(online, clickAndCollect, b2bClickAndCollect, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer suggested pickup store
     * @param {boolean} [defaultToBaseStore] defaultToBaseStore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getSuggestedPickUpStoreUsingGET(defaultToBaseStore?: boolean, options?: AxiosRequestConfig) {
        return StoreApiFp(this.configuration).getSuggestedPickUpStoreUsingGET(defaultToBaseStore, options).then((request) => request(this.axios, this.basePath));
    }
}
