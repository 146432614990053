import { focusBoxShadow } from '@styles/utils';
import styled from 'styled-components';

export const StyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  &:focus-visible {
    ${focusBoxShadow}
  }
`;
