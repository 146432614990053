import ProductBreadcrumbs from '@components/molecules/Breadcrumbs/ProductBreadcrumbs';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Header = styled.div`
  min-height: ${rem(300)};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  width: 100%;
  ${media.tabletPortrait} {
    padding: ${rem(25)} ${rem(7)} 0;
  }
  ${media.tabletLandscape} {
    padding: ${rem(35)} ${rem(54)} 0;
  }
  ${media.desktop} {
    padding: ${rem(35)} ${rem(58)} 0;
    width: ${rem(1120)};
  }
`;

export const ImageSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: ${rem(16)};
  ${media.tabletPortrait} {
    max-width: 50%;
    margin: 0;
    justify-content: flex-start;
  }
  ${media.tabletLandscape} {
    max-width: 45%;
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: ${rem(200)};
  position: relative;
  display: flex;
  justify-content: center;
  height: ${rem(200)};
  margin: 0 auto;
  ${media.tabletPortrait} {
    width: ${rem(305)};
    height: ${rem(305)};
  }
`;

export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${media.tabletPortrait} {
    min-width: ${rem(365)};
    margin-left: ${rem(10)};
  }
  ${media.desktop} {
    min-width: ${rem(498)};
  }
`;

export const SplashSection = styled.div`
  margin-bottom: ${rem(7)};
`;

export const StyledProductBreadcrumbs = styled(ProductBreadcrumbs)`
  display: flex;
  flex-wrap: wrap;
  margin: ${rem(8)};
  max-width: 85%;
  ${media.tabletPortrait} {
    max-width: 100%;
    margin: 0 0 ${rem(20)};
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(10)};
  margin-bottom: ${rem(10)};
  ${media.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${rem(30)};
    padding: 0;
  }
`;

export const QuantityAndPromotionLimitWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${media.tabletPortrait} {
    flex-direction: row;
  }
`;

export const QuantityWrapper = styled.div`
  margin: ${rem(10)} auto 0;
  width: ${rem(220)};
  max-width: ${rem(220)};

  ${media.tabletPortrait} {
    margin: ${rem(5)} 0;
  }
`;

export const RedeemLabel = styled.div`
  font-size: ${rem(12)};
  margin: ${rem(5)} 0 0 ${rem(15)};
  ${media.tabletPortrait} {
    margin-top: 0;
  }
`;

export const PromotionSplashPosition = styled.div`
  position: absolute;
  z-index: 1;
  right: ${rem(40)};
  top: auto;
  bottom: ${rem(12)};
  ${media.tabletPortrait} {
    bottom: 0;
    top: 0;
    right: 0;
  }
`;

export const PromotionUsed = styled.div`
  margin: ${rem(5)} 0 0 ${rem(15)};
`;

export const RedeemAndUsedPromotionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
