import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { focusBoxShadow } from '@styles/utils';
import { rem } from 'polished';
import styled from 'styled-components';
import { IconWrapper } from '../../Icon/Icon.styles';
import { ButtonVariants, IButtonProps } from './Button';

interface IStyledButtonProps {
  readonly $fullWidth?: boolean;
  readonly $size?: 'medium' | 'large';
  readonly $variant: ButtonVariants;
  readonly disabled?: boolean;
  readonly className?: string;
  $withIcon: boolean;
  $center: boolean;
}

export const ButtonBase = styled.button<IStyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  font-family: ${({ theme }) => theme.typography.fontSans};
  border-radius: ${rem(40)};
  height: ${rem(40)};
  line-height: ${rem(20)};
  min-width: ${rem(176)};
  padding: 0 ${rem(30)};
  outline: 0;
  position: relative;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
  border: 0;
  &:focus-visible {
    ${focusBoxShadow}
  }
  &:disabled {
    background: ${({ theme }) => theme.colors.colorDesertStorm};
    color: ${({ theme }) => theme.colors.colorSilver};
    cursor: default;
    pointer-events: none;
    ${(props) =>
      props.$variant === 'none' &&
      `
        background: none;
      `}
  }
  ${(props) => props.$fullWidth && `width: 100%;`}
  ${(props) =>
    props.$size === 'medium' &&
    `
    && {min-width: ${rem(220)}};
    `}
  ${(props) =>
    props.$size === 'large' &&
    `
      width: ${rem(237)};
      font-size: ${rem(24)};
      height: ${rem(52)};
      ${media.tabletPortrait} {
        width: ${rem(414)};
        height: ${rem(64)};
      }
    `}
  ${({ $withIcon }) =>
    $withIcon &&
    `
    ${IconWrapper} {
      position: absolute;
      top: ${rem(8)};
      left: ${rem(8)};
    }
  `}
    ${({ $center }) =>
    $center &&
    `
    margin: 0 auto;
  `}
`;
export const StyledIcon = styled(Icon)<{ isExpanded: boolean }>`
  ${({ isExpanded }) =>
    isExpanded &&
    `
      transform: rotate(180deg);
  `}
`;

const ButtonPrimary = styled(ButtonBase)`
  transition: background-color 0.5s ease;
  background: ${({ theme }) => theme.colors.colorPrimary};
  color: ${({ theme }) => theme.colors.colorWhite};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.colorCardinal};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorThunderbird};
  }
`;

const ButtonSecondary = styled(ButtonBase)`
  transition: background-color 0.5s ease;
  background: ${({ theme }) => theme.colors.colorSecondary};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.colorIron};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorSilver};
  }
`;

const ButtonHighlight = styled(ButtonBase)`
  transition: background-color 0.5s ease;
  background: ${({ theme }) => theme.colors.colorHighlight};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.colorBeautyBush};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorPetiteOrchid};
  }
`;

const ButtonTransparent = styled(ButtonBase)`
  background: none;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
`;

const ButtonLink = styled(ButtonBase)`
  text-decoration: underline;
  background: none;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.colorTextSecondary};
    }
  }
`;

export const ButtonDropdownContainer = styled.button`
  background-color: white;
  display: flex;
  align-items: center;
  padding: ${rem(8)} ${rem(10)};
  width: 100%;
  border: solid ${rem(2)};
  cursor: pointer;
  // combine with a max-width wrapper to give children proper text-overflow
  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const ButtonPink = styled(ButtonBase)`
  transition: background-color 0.5s ease;
  background: ${({ theme }) => theme.colors.colorPrimaryPink};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.colorBeautyBush};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorPetiteOrchid};
  }
`;

const ButtonWhite = styled(ButtonBase)`
  background: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  @media (hover: hover) {
    &:hover {
      background: ${({ theme }) => theme.colors.colorWhite};
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorWhite};
  }
`;

const ButtonNone = styled(ButtonBase)`
  background: none;
  padding: 0;
  min-width: 0;
  color: inherit;
  @media (hover: hover) {
    &:hover {
      background: none;
    }
  }
  &:focus {
    background: none;
  }
`;

/** This can't be reached cuz no valid option in typescript
const ButtonOutlined = styled(ButtonBase)`
  background: ${({ theme }) => theme.colors.colorWhite};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.colorCararra};

  &:focus {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.colorPrimary};
  }

  &:hover {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.colorPrimary};
  }
`;
*/

/* eslint-disable react/jsx-props-no-spreading */
export const StyledButton = (props: IButtonProps & IStyledButtonProps) => {
  const { $variant } = props;
  switch ($variant) {
    case 'primary':
    case 'red':
      return <ButtonBase as={ButtonPrimary} {...props} />;
    case 'grey':
    case 'secondary':
      return <ButtonBase as={ButtonSecondary} {...props} />;
    case 'highlight':
      return <ButtonBase as={ButtonHighlight} {...props} />;
    case 'transparent':
      return <ButtonBase as={ButtonTransparent} {...props} />;
    case 'link':
      return <ButtonBase as={ButtonLink} {...props} />;
    case 'pink':
      return <ButtonBase as={ButtonPink} {...props} />;
    case 'white':
      return <ButtonBase as={ButtonWhite} {...props} />;
    case 'none':
      return <ButtonBase as={ButtonNone} {...props} />;
    default:
      return <ButtonBase {...props} />;
  }
};
